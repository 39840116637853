import moment from "moment"
import {
	get,
	post,
	put,
	rsaaTypes,
	RSAA,
	patch,
	del,
} from "@ncs/bricks/middleware/portalApiMiddleware"

const FETCH_LABOR_RATE_PRICES = rsaaTypes("pricing/labor_rates/list")
const GET_LABOR_RATE_PRICE = rsaaTypes("pricing/labor_rate/get")
const SAVE_LABOR_RATE_PRICE = rsaaTypes("pricing/labor_rates/put")
const FETCH_ASSIGNMENTS = rsaaTypes("pricing/labor_rate/assignments/list")
const GET_GROUP_REVENUE_PROJECTION = rsaaTypes("pricing/group_projection/post")
const MIGRATE_CUSTOMERS = rsaaTypes("pricing/migrate_customers/post")
const GET_LINE_PRICING = rsaaTypes("pricing/line_item_pricing/post")
const GET_TOTAL_TAXES = rsaaTypes("pricing/get_total_taxes/post")
const CREATE_LABOR_RATE_PRICE = rsaaTypes("pricing/create/price")
const GET_PRICE_MATRICIES = rsaaTypes("pricing/price_matrix/list")
const PATCH_PRICE_MATRIX = rsaaTypes("pricing/price_matrix/patch")
const CREATE_PRICE_MATRIX = rsaaTypes("pricing/price_matrix/post")
const GET_PRICE_MATRIX = rsaaTypes("pricing/price_matrix/get")
const LIST_PRICE_MATRIX_ITEMS = rsaaTypes("pricing/price_matrix_items/list")
const DELETE_PRICE_MATRIX_ITEM = rsaaTypes("pricing/price_matrix_items/delete")
const CREATE_PRICE_MATRIX_ITEM = rsaaTypes("pricing/price_matrix_items/post")
const ADD_CUSTOMER_PRICE_MATRIX = rsaaTypes("pricing/price_matrix/add_customer")

export const fetchLaborRatePrices = (querystringParams) => ({
	[RSAA]: {
		api: get(`/pricing/labor_rate_prices/`, querystringParams),
		types: FETCH_LABOR_RATE_PRICES,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const getLaborRatePrice = (id) => ({
	[RSAA]: {
		api: get(`/pricing/labor_rate_prices/${id}/`),
		types: GET_LABOR_RATE_PRICE,
	},
})

export const createLaborRatePrice = (params) => ({
	[RSAA]: {
		api: post(`/pricing/labor_rate_prices/`, params),
		types: CREATE_LABOR_RATE_PRICE,
	},
})

export const saveLaborRatePrice = (
	id,
	{
		description,
		rate: locked_rate,
		isMultiTerritory: is_multi_territory,
		customersAdded: customers_added,
		customersRemoved: customers_removed,
		effectiveDate: effective_date,
	}
) => ({
	[RSAA]: {
		api: put(`/pricing/labor_rate_prices/${id}/`, {
			description,
			locked_rate,
			is_multi_territory,
			customers_added,
			customers_removed,
			effective_date:
				typeof effective_date === "undefined" ? undefined : (
					moment(effective_date).format("YYYY-MM-DD")
				),
		}),
		types: SAVE_LABOR_RATE_PRICE,
	},
})
export const getPriceMatrixes = (querystringParams) => ({
	[RSAA]: {
		api: get(`/pricing/price_matrix/`, querystringParams),
		types: GET_PRICE_MATRICIES,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const getPriceMatrix = (id) => ({
	[RSAA]: {
		api: get(`/pricing/price_matrix/${id}/`),
		types: GET_PRICE_MATRIX,
	},
})

export const createPriceMatrix = (data) => ({
	[RSAA]: {
		api: post(`/pricing/price_matrix/`, data),
		types: CREATE_PRICE_MATRIX,
	},
})

export const patchPriceMatrix = (id, data) => ({
	[RSAA]: {
		api: patch(`/pricing/price_matrix/${id}/`, data),
		types: PATCH_PRICE_MATRIX,
	},
})

export const addCustomerToPricingMatrix = (id, customerId) => ({
	[RSAA]: {
		api: post(`/pricing/price_matrix/${id}/add_customer/`, { customer_id: customerId }),
		types: ADD_CUSTOMER_PRICE_MATRIX,
	},
})

export const listPriceMatrixItems = (querystringParams) => ({
	[RSAA]: {
		api: get(`/pricing/price_matrix_items/`, querystringParams),
		types: LIST_PRICE_MATRIX_ITEMS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})
export const deletePriceMatrixItem = (id) => ({
	[RSAA]: {
		api: del(`/pricing/price_matrix_items/${id}/`),
		types: DELETE_PRICE_MATRIX_ITEM,
	},
})

export const createPriceMatrixItem = (data) => ({
	[RSAA]: {
		api: post(`/pricing/price_matrix_items/`, {
			...data,
			discount_rate: data.discount_rate / 100.0,
		}),
		types: CREATE_PRICE_MATRIX_ITEM,
	},
})

export const migrateCustomers = (id, { newGroup, effectiveDate: effective_date }) => ({
	[RSAA]: {
		api: post(`/pricing/labor_rate_prices/${id}/migrate_customers/`, {
			labor_rate_price_id: newGroup.id,
			effective_date:
				typeof effective_date === "undefined" ? undefined : (
					moment(effective_date).format("YYYY-MM-DD")
				),
		}),
		types: MIGRATE_CUSTOMERS,
	},
})

export const listAssignmentsForLaborRatePrice = (id) => (querystringParams) => ({
	[RSAA]: {
		api: get(`/pricing/labor_rate_prices/${id}/assignments/`, querystringParams),
		types: FETCH_ASSIGNMENTS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const getGroupRevenueProjection = ({
	group,
	rate: new_price,
	customersAdded: customers_added,
	customersRemoved: customers_removed,
	effectiveDate: effective_date,
}) => ({
	[RSAA]: {
		api: post("/pricing/group_projection/", {
			group_price_id: group.id,
			new_price,
			customers_added,
			customers_removed,
			effective_date,
		}),
		types: GET_GROUP_REVENUE_PROJECTION,
	},
})

export const getLinePricing = (params) => ({
	[RSAA]: {
		api: post(`/pricing/line_pricing/`, params),
		types: GET_LINE_PRICING,
	},
})

export const postTotalTaxes = (params) => ({
	[RSAA]: {
		api: post(`/pricing/tax_estimate/`, params),
		types: GET_TOTAL_TAXES,
	},
})

const initialState = {
	error: false,

	isLoadingLaborRatePrices: false,
	laborRatePrices: [],

	isLoadingLaborRatePrice: false,
	laborRatePrice: null,

	isLoadingPriceMatricies: false,
	priceMatricies: [],

	isLoadingPriceMatrix: false,
	priceMatrix: [],

	isLoadingAssignments: false,
	assignments: [],
}

const pricing = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_LABOR_RATE_PRICES.request:
		case FETCH_LABOR_RATE_PRICES.success:
		case FETCH_LABOR_RATE_PRICES.failure:
			return {
				...state,
				isLoadingLaborRatePrices: action.type === FETCH_LABOR_RATE_PRICES.request,
				laborRatePrices:
					action.type === FETCH_LABOR_RATE_PRICES.success ?
						action.payload
					:	state.laborRatePrices,
				error: action.error,
			}

		case GET_LABOR_RATE_PRICE.request:
		case GET_LABOR_RATE_PRICE.success:
		case GET_LABOR_RATE_PRICE.failure:
			return {
				...state,
				isLoadingLaborRatePrice: action.type === GET_LABOR_RATE_PRICE.request,
				laborRatePrice:
					action.type === GET_LABOR_RATE_PRICE.success ?
						action.payload
					:	state.laborRatePrice,
				error: action.error,
			}
		case GET_PRICE_MATRIX.request:
		case GET_PRICE_MATRIX.success:
		case GET_PRICE_MATRIX.failure:
			console.log("payload is", action.payload)
			return {
				...state,
				isLoadingPriceMatrix: action.type === GET_PRICE_MATRIX.request,
				priceMatrix:
					action.type === GET_PRICE_MATRIX.success ? action.payload : state.priceMatrix,
				error: action.error,
			}
		case GET_PRICE_MATRICIES.request:
		case GET_PRICE_MATRICIES.success:
		case GET_PRICE_MATRICIES.failure:
			return {
				...state,
				isLoadingPriceMatrix: action.type === GET_PRICE_MATRICIES.request,
				priceMatrix:
					action.type === GET_PRICE_MATRICIES.success ?
						action.payload
					:	state.priceMatrix,
				error: action.error,
			}
		case FETCH_ASSIGNMENTS.request:
		case FETCH_ASSIGNMENTS.success:
		case FETCH_ASSIGNMENTS.failure:
			return {
				...state,
				isLoadingAssignments: action.type === FETCH_ASSIGNMENTS.request,
				assignments:
					action.type === FETCH_ASSIGNMENTS.success ?
						action.payload
					:	state.laborRatePrices,
				error: action.error,
			}

		default:
			return state
	}
}
export default pricing
