import React from "react"

import InputAdornment from "@material-ui/core/InputAdornment"
import withStyles from "@material-ui/core/styles/withStyles"
import PropTypes from "prop-types"
import SweetAlert from "react-bootstrap-sweetalert"
import ReactTable from "react-table-6"

import ExcelIcon from "@ncs/bricks/assets/img/ExcelIcon.svg"
import PDFIcon from "@ncs/bricks/assets/img/PDFIcon.svg"
import WordIcon from "@ncs/bricks/assets/img/WordIcon.svg"

import sweetAlertStyle from "@ncs/bricks/assets/jss/material-dashboard-pro-react/views/sweetAlertStyle"
import { formatDateTime, formatNumberAsCurrency } from "@ncs/bricks/util/formatters"

import {
	Alert,
	Button,
	ButtonWithIcon,
	ColumnChooser,
	ConditionalContent,
	Danger,
	Dialog,
	GridContainer,
	GridItem,
	Input,
	InputAsDisplay,
	LoadingWrapper,
	QuickCard,
	ResetFiltersButton,
	Select,
} from "~/components"

import CategoriesMenuContainer from "./CategoriesMenuContainer"

import Add from "@material-ui/icons/Add"
import AssignmentReturn from "@material-ui/icons/AssignmentReturn"
import AttachFile from "@material-ui/icons/AttachFile"
import Check from "@material-ui/icons/Check"
import ChevronRight from "@material-ui/icons/ChevronRight"
import CloudDownload from "@material-ui/icons/CloudDownload"
import Delete from "@material-ui/icons/Delete"
import Edit from "@material-ui/icons/Edit"
import Remove from "@material-ui/icons/Remove"
import Search from "@material-ui/icons/Search"
import Share from "@material-ui/icons/Share"
import ShoppingCart from "@material-ui/icons/ShoppingCart"
import TimeToLeave from "@material-ui/icons/TimeToLeave"
import Videocam from "@material-ui/icons/Videocam"
import ZoomIn from "@material-ui/icons/ZoomIn"

const styles = {
	clickableRow: {
		cursor: "pointer",
	},
	columnChooser: {
		margin: "15px",
	},
	inputAdornmentIcon: {
		color: "#555",
	},
	right: {
		textAlign: "right",
	},
	bottom: {
		marginTop: "19px",
	},
	center: {
		textAlign: "center",
	},
	previewIcon: {
		width: 75,
		height: 75,
	},
	previewImage: {
		maxWidth: "75px",
		maxHeight: "100px",
		height: "auto",
		width: "auto",
	},
	...sweetAlertStyle,
}

const MarketingMaterials = (props) => {
	const {
		classes,
		isAdmin,
		isLoading,
		materials,
		onViewPreview,
		onDownload,
		onGenerateLink,
		onCloseGeneratedLink,
		isGeneratingLink,
		generatedLink,
		pages,
		search,
		sorted,
		page,
		pageSize,
		expanded,
		resized,
		filtered,
		onSearchChange,
		onSortedChange,
		onPageChange,
		onPageSizeChange,
		onExpandedChange,
		onResizedChange,
		onFilteredChange,
		dateRanges,
		selectedCreatedDateRange,
		onCreatedDateRangeChange,
		availableDocumentTypeFilters,
		selectedDocumentTypeFilter,
		onDocumentTypeChange,
		onResetFilters,
		isSaving,
		isConfirmingDelete,
		deletingItem,
		onDelete,
		onCancelDelete,
		onConfirmDelete,
		showCategorySidebar,
		onToggleCategorySidebar,
		onCategoryChange,
		selectedCategory,
		categories,
		toggleColumn,
		columns,
		availableColumns,
		selectedColumns,
		isAddingToCart,
		addingToCartItem,
		addingToCartQuantity,
		onAddingToCartQuantityChange,
		onCancelAddingToCart,
		onFinishAddingToCart,
		showAddedToCart,
		onCloseAddedToCart,
		onViewCart,
		cart,
		currentCategory,
		isRemovingCategory,
		onStartRemovingCategory,
		onRemoveCategory,
		categoryName,
		onCategoryNameChange,
		onCancelEditingCategory,
		onSaveCategory,
		isSavingCategory,
		onCreateCategory,
		isCreatingCategory,
		onEditCategory,
		isEditingCategory,
		editingCategory,
		selectedCategoryTree,
	} = props

	const generateCategoryOptions = (cats, tier) => {
		if (!cats || !cats.length) {
			return []
		}

		let options = [
			{ value: "-1", text: "All Categories" },
			...cats.map((x) => ({
				value: x.id.toString(),
				text: x.name,
			})),
		]

		if (isAdmin) {
			options.push({ value: `add-new-tier-${tier}`, text: "Add New Category" })
		}

		return options
	}

	const generatePreview = (item) => {
		switch (item.extension.toLowerCase()) {
			case "xls":
			case "xlsx":
				return <img height={40} width={40} src={ExcelIcon} alt="Excel Document" />
			case "docx":
			case "doc":
				return <img height={40} width={40} src={WordIcon} alt="Word Document" />
			case "pdf":
				return <img height={40} width={40} src={PDFIcon} alt="PDF Document" />
			case "mp4":
				return <Videocam size="large" alt="Video Document" />
			default:
				return <AttachFile size="large" alt="Attach File" />
		}
	}

	return (
		<React.Fragment>
			<GridContainer>
				<GridItem xs={12} md={4} lg={3} show={showCategorySidebar}>
					<CategoriesMenuContainer
						show={showCategorySidebar}
						onToggleVisibility={onToggleCategorySidebar}
					/>
				</GridItem>
				<GridItem
					xs={12}
					md={showCategorySidebar ? 8 : 12}
					lg={showCategorySidebar ? 9 : 12}
				>
					<QuickCard title={"Marketing Materials"} icon={<TimeToLeave />}>
						<GridContainer justify="flex-start">
							<GridItem xs={12} sm={10} md={4}>
								<Input
									id="search"
									labelText="Search"
									formControlProps={{
										fullWidth: true,
									}}
									value={search}
									inputProps={{
										autoFocus: true,
										placeholder: "Search all fields...",
										onChange: (e) => onSearchChange(e.target.value),
										startAdornment: (
											<InputAdornment position="start">
												<Search className={classes.inputAdornmentIcon} />
											</InputAdornment>
										),
									}}
								/>
							</GridItem>
							<GridItem xs={12} sm={10} md={4}>
								<Select
									id="createdDateStart"
									labelText="Created Date"
									value={selectedCreatedDateRange}
									onChange={onCreatedDateRangeChange}
									options={dateRanges}
								/>
							</GridItem>
							<GridItem xs={12} sm={10} md={4}>
								<Select
									id="document_type_id"
									labelText="Document Type"
									value={selectedDocumentTypeFilter || "-1"}
									onChange={onDocumentTypeChange}
									options={availableDocumentTypeFilters}
								/>
							</GridItem>
							<GridItem xs={12} md={4}>
								<Select
									id="first_category"
									labelText="Top-level Category"
									value={(selectedCategoryTree.top || { id: -1 }).id.toString()}
									onChange={onCategoryChange}
									options={generateCategoryOptions(categories, 1)}
									emptyItemsText="Loading..."
								/>
								<ConditionalContent show={isAdmin && !!selectedCategoryTree.top}>
									<ButtonWithIcon
										content="Edit Top-level Category"
										icon={<Edit />}
										onClick={() => onEditCategory(selectedCategoryTree.top)}
										round
										color="warning"
										size="sm"
									/>
								</ConditionalContent>
							</GridItem>
							<GridItem xs={12} md={4}>
								<ConditionalContent
									show={
										selectedCategoryTree.top &&
										selectedCategoryTree.top.sub_categories.length > 0
									}
								>
									{() => (
										<Select
											id="second_category"
											labelText="Second-level Category"
											value={(
												selectedCategoryTree.mid || { id: -1 }
											).id.toString()}
											onChange={onCategoryChange}
											options={generateCategoryOptions(
												selectedCategoryTree.top.sub_categories,
												2
											)}
											emptyItemsText="Loading..."
										/>
									)}
								</ConditionalContent>
								<ConditionalContent
									show={
										selectedCategoryTree.top &&
										selectedCategoryTree.top.sub_categories.length === 0
									}
								>
									{() => (
										<ButtonWithIcon
											content="Create Second-level Category"
											icon={<Add />}
											onClick={() =>
												onCreateCategory(selectedCategoryTree.top)
											}
											round
											color="success"
											size="sm"
										/>
									)}
								</ConditionalContent>
								<ConditionalContent show={isAdmin && !!selectedCategoryTree.mid}>
									<ButtonWithIcon
										content="Edit Second-level Category"
										icon={<Edit />}
										onClick={() => onEditCategory(selectedCategoryTree.mid)}
										round
										color="warning"
										size="sm"
									/>
								</ConditionalContent>
							</GridItem>
							<GridItem xs={12} md={4}>
								<ConditionalContent
									show={
										selectedCategoryTree.mid &&
										selectedCategoryTree.mid.sub_categories.length > 0
									}
								>
									{() => (
										<Select
											id="third_category"
											labelText="Third-level Category"
											value={(
												selectedCategoryTree.bottom || { id: -1 }
											).id.toString()}
											onChange={onCategoryChange}
											options={generateCategoryOptions(
												selectedCategoryTree.mid.sub_categories,
												3
											)}
											emptyItemsText="Loading..."
										/>
									)}
								</ConditionalContent>
								<ConditionalContent
									show={
										selectedCategoryTree.mid &&
										selectedCategoryTree.mid.sub_categories.length === 0
									}
								>
									{() => (
										<ButtonWithIcon
											content="Create Third-level Category"
											icon={<Add />}
											onClick={() =>
												onCreateCategory(selectedCategoryTree.mid)
											}
											round
											color="success"
											size="sm"
										/>
									)}
								</ConditionalContent>
								<ConditionalContent
									show={isAdmin && !!selectedCategoryTree.bottom}
								>
									<ButtonWithIcon
										content="Edit Third-level Category"
										icon={<Edit />}
										onClick={() => onEditCategory(selectedCategoryTree.bottom)}
										round
										color="warning"
										size="sm"
									/>
								</ConditionalContent>
							</GridItem>
						</GridContainer>
						<GridContainer justify="space-between">
							<GridItem xs={6}>
								<ResetFiltersButton onResetFilters={onResetFilters} />
								<ConditionalContent hide={showCategorySidebar}>
									<Button
										simple
										color="primary"
										onClick={onToggleCategorySidebar}
									>
										<ChevronRight /> {"Show Category Tree"}
									</Button>
								</ConditionalContent>
							</GridItem>
							<GridItem xs={6} className={classes.right + " " + classes.bottom}>
								<ColumnChooser
									toggleColumn={toggleColumn}
									selectedColumns={selectedColumns}
									toggleableColumns={availableColumns.filter(
										(col) =>
											![
												columns.Preview,
												columns.Filename,
												columns.Actions,
											].includes(col)
									)}
								/>
							</GridItem>
							<ConditionalContent show={Object.keys(cart).length > 0}>
								<GridItem xs={12} className={classes.right + " " + classes.bottom}>
									<ButtonWithIcon
										content="View Cart"
										icon={<ShoppingCart />}
										color="success"
										size="sm"
										round
										onClick={onViewCart}
									/>
								</GridItem>
							</ConditionalContent>
						</GridContainer>

						{(
							isAdmin &&
							currentCategory &&
							currentCategory.document_count === 0 &&
							currentCategory.sub_categories.length === 0 &&
							isLoading === false &&
							selectedCategory !== -1 &&
							materials.length === 0
						) ?
							<GridContainer>
								<GridItem xs={12} className={classes.center}>
									<Danger>{`No documents or sub categories exist for category: ${currentCategory.name}`}</Danger>
									<ButtonWithIcon
										icon={<Delete />}
										content={
											isRemovingCategory ?
												isSavingCategory ?
													"Removing Category..."
												:	"Are you sure?"
											:	"Remove Category"
										}
										onClick={
											isRemovingCategory ? onRemoveCategory : (
												onStartRemovingCategory
											)
										}
										disabled={isSavingCategory}
										color="danger"
										round
										size="sm"
									/>
								</GridItem>
							</GridContainer>
						:	<ReactTable
								manual
								loading={isLoading}
								noDataText={
									isLoading ? "Loading..." : (
										"No marketing materials match your criteria."
									)
								}
								data={materials}
								minRows={0}
								columns={selectedColumns.map((column) => {
									switch (column) {
										case columns.Preview:
											return {
												Header: "Preview",
												accessor: generatePreview,
												id: "preview",
												sortable: false,
											}
										case columns.Filename:
											return {
												Header: "Filename",
												accessor: (x) => `${x.filename}.${x.extension}`,
												id: "filename",
												sortable: true,
											}
										case columns.Category:
											return {
												Header: "Category",
												accessor: "category.name",
												sortable: true,
											}
										case columns.DateCreated:
											return {
												Header: "Date Created",
												accessor: (x) => formatDateTime(x.created_date),
												id: "created_date",
												sortable: true,
											}
										case columns.EffectiveDate:
											return {
												Header: "Effective Date",
												accessor: (x) => formatDateTime(x.effective_date),
												id: "effective_date",
												sortable: true,
											}
										case columns.ExpirationDate:
											return {
												Header: "Expiration Date",
												accessor: (x) => formatDateTime(x.expiration_date),
												id: "expiration_date",
												sortable: true,
											}
										case columns.Cost:
											return {
												Header: "Cost",
												accessor: (x) => formatNumberAsCurrency(x.cost),
												id: "cost",
												sortable: true,
											}
										case columns.IsOrderable:
											return {
												Header: "Orderable?",
												headerStyle: styles.center,
												accessor: (x) =>
													x.is_orderable ?
														<div className={classes.center}>
															<Check />
														</div>
													:	null,
												id: "is_orderable",
												sortable: true,
											}
										case columns.CreatedBy:
											return {
												Header: "Created By",
												accessor: "uploaded_by.name",
												sortable: true,
											}
										case columns.Actions:
											return {
												Header: "Actions",
												headerStyle: styles.center,
												accessor: (item) => (
													<div className={classes.center}>
														<Button
															justIcon
															round
															className="edit"
															color="info"
															size="sm"
															title="Preview"
															onClick={(e) => {
																e.stopPropagation()
																if (
																	"document" ===
																	item.document_type_name.toLowerCase()
																) {
																	onDownload(item)
																} else {
																	onViewPreview(item)
																}
															}}
														>
															{(
																["video", "image"].includes(
																	item.document_type_name.toLowerCase()
																)
															) ?
																<ZoomIn />
															:	<CloudDownload />}
														</Button>
														<Button
															justIcon
															round
															color="warning"
															size="sm"
															title="Share"
															onClick={(e) => {
																e.stopPropagation()
																onGenerateLink(item)
															}}
														>
															<Share />
														</Button>
														<ConditionalContent show={isAdmin}>
															<Button
																justIcon
																round
																color="danger"
																size="sm"
																title="Delete"
																onClick={(e) => {
																	e.stopPropagation()
																	onDelete(item.id)
																}}
															>
																<Remove />
															</Button>
														</ConditionalContent>
													</div>
												),
												id: "actions",
												sortable: false,
											}
										default:
											return null
									}
								})}
								pages={pages}
								defaultPageSize={25}
								showPaginationTop
								showPaginationBottom
								className="-striped -highlight"
								getTrProps={(state, rowInfo) => ({
									onClick: () => onViewPreview(rowInfo.original),
									className: classes.clickableRow,
								})}
								// fully control the table with redux
								sorted={sorted}
								page={page}
								pageSize={pageSize}
								expanded={expanded}
								resized={resized}
								filtered={filtered}
								onSortedChange={onSortedChange}
								onPageChange={onPageChange}
								onPageSizeChange={onPageSizeChange}
								onExpandedChange={onExpandedChange}
								onResizedChange={onResizedChange}
								onFilteredChange={onFilteredChange}
							/>
						}
					</QuickCard>
				</GridItem>
			</GridContainer>

			<Dialog
				warning="true"
				show={isConfirmingDelete}
				title={"Are you sure you want to permanently remove this item?"}
				confirmBtnIcon={<Delete />}
				confirmBtnText="Confirm Deletion"
				confirmBtnColor="danger"
				onConfirm={onConfirmDelete}
				isConfirmationAsync={true}
				cancelBtnText={"Cancel"}
				onCancel={onCancelDelete}
				successTitle="Success"
				successContent="Marketing Material was removed."
				onSuccessConfirm={onCancelDelete}
				isSaving={isSaving}
			>
				{isConfirmingDelete && (
					<GridContainer className={classes.leftAlignedModal}>
						<GridItem xs={12}>
							<InputAsDisplay
								labelText="Category"
								value={deletingItem.category.name}
							/>
						</GridItem>
						<GridItem xs={12} sm={4}>
							{generatePreview(deletingItem)}
						</GridItem>
						<GridItem xs={12} sm={8}>
							<InputAsDisplay
								labelText="Filename"
								value={`${deletingItem.filename}.${deletingItem.extension}`}
							/>
						</GridItem>
					</GridContainer>
				)}
			</Dialog>

			<Dialog
				show={isAddingToCart}
				title="Choose quantity to order"
				confirmBtnIcon={<ShoppingCart />}
				confirmBtnText="Add to Cart"
				confirmBtnColor="success"
				onConfirm={onFinishAddingToCart}
				cancelBtnText={"Cancel"}
				onCancel={onCancelAddingToCart}
			>
				{isAddingToCart && (
					<GridContainer className={classes.leftAlignedModal}>
						<GridItem xs={12}>
							<Select
								id="addingToCartQuantity"
								labelText="Quantity"
								value={addingToCartQuantity}
								onChange={onAddingToCartQuantityChange}
								options={[
									{ value: 1, text: "1" },
									{ value: 10, text: "10" },
									{ value: 25, text: "25" },
									{ value: 50, text: "50" },
								]}
							/>
						</GridItem>
						<GridItem xs={12}>
							<InputAsDisplay
								labelText="Filename"
								value={addingToCartItem.filename}
							/>
						</GridItem>
					</GridContainer>
				)}
			</Dialog>

			<Dialog
				show={isCreatingCategory || isEditingCategory}
				title={
					isEditingCategory ?
						`Edit Category: ${editingCategory.name}`
					:	"Create New Category"
				}
				confirmBtnIcon={<Check />}
				confirmBtnText="Save"
				confirmBtnColor="success"
				onConfirm={onSaveCategory}
				isConfirmationAsync={true}
				cancelBtnText={"Cancel"}
				onCancel={onCancelEditingCategory}
				isSaving={isSavingCategory}
			>
				<React.Fragment>
					{(isCreatingCategory || isEditingCategory) && (
						<GridContainer className={classes.leftAlignedModal}>
							<GridItem xs={12}>
								<Input
									labelText="Category Name"
									value={categoryName}
									onChange={onCategoryNameChange}
									inputProps={{ autoFocus: true }}
								/>
							</GridItem>
						</GridContainer>
					)}
				</React.Fragment>
			</Dialog>

			<Alert
				success
				show={showAddedToCart}
				title="Item Added to Cart"
				onCancel={onCloseAddedToCart}
				cancelBtnText="Continue Shopping"
				onConfirm={onViewCart}
				confirmBtnText="View Cart"
			/>

			<LoadingWrapper isLoading={isGeneratingLink} title="Creating Shareable Link" />

			<SweetAlert
				success
				show={typeof generatedLink === "string"}
				title="Shareable Link"
				showConfirm={false}
				onConfirm={onCloseGeneratedLink}
			>
				<Input
					formControlProps={{ fullWidth: true }}
					labelText="Link"
					inputProps={{
						id: "generatedLink",
						autoFocus: true,
						multiline: true,
						value: generatedLink,
					}}
				/>
				<ButtonWithIcon
					id="clipboardButton"
					data-clipboard-target="#generatedLink"
					icon={<AssignmentReturn />}
					content="Copy to Clipboard"
					color="info"
					onClick={onCloseGeneratedLink}
				/>
			</SweetAlert>
		</React.Fragment>
	)
}

MarketingMaterials.propTypes = {
	classes: PropTypes.object.isRequired,
	isAdmin: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	materials: PropTypes.array.isRequired,
	pages: PropTypes.number.isRequired,
	onViewPreview: PropTypes.func.isRequired,
	onDownload: PropTypes.func.isRequired,
	search: PropTypes.string.isRequired,
	sorted: PropTypes.array.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	expanded: PropTypes.object.isRequired,
	resized: PropTypes.array.isRequired,
	filtered: PropTypes.array.isRequired,
	onSearchChange: PropTypes.func.isRequired,
	onSortedChange: PropTypes.func.isRequired,
	onPageChange: PropTypes.func.isRequired,
	onPageSizeChange: PropTypes.func.isRequired,
	onExpandedChange: PropTypes.func.isRequired,
	onResizedChange: PropTypes.func.isRequired,
	onFilteredChange: PropTypes.func.isRequired,

	columns: PropTypes.object.isRequired,
	availableColumns: PropTypes.array.isRequired,
	selectedColumns: PropTypes.array.isRequired,
	toggleColumn: PropTypes.func.isRequired,

	dateRanges: PropTypes.array.isRequired,

	selectedCreatedDateRange: PropTypes.string.isRequired,
	onCreatedDateRangeChange: PropTypes.func.isRequired,

	availableDocumentTypeFilters: PropTypes.array.isRequired,
	selectedDocumentTypeFilter: PropTypes.string,
	onDocumentTypeChange: PropTypes.func,

	onResetFilters: PropTypes.func,

	isSaving: PropTypes.bool.isRequired,
	isConfirmingDelete: PropTypes.bool.isRequired,
	deletingItem: PropTypes.object,
	onDelete: PropTypes.func.isRequired,
	onCancelDelete: PropTypes.func.isRequired,
	onConfirmDelete: PropTypes.func.isRequired,

	showCategorySidebar: PropTypes.bool.isRequired,
	onToggleCategorySidebar: PropTypes.func.isRequired,
	categories: PropTypes.array.isRequired,
	selectedCategory: PropTypes.number,
	selectedCategoryTree: PropTypes.object.isRequired,
	onCategoryChange: PropTypes.func.isRequired,

	onGenerateLink: PropTypes.func.isRequired,
	onCloseGeneratedLink: PropTypes.func.isRequired,
	isGeneratingLink: PropTypes.bool.isRequired,
	generatedLink: PropTypes.string,

	onStartAddingToCart: PropTypes.func.isRequired,
	isAddingToCart: PropTypes.bool.isRequired,
	addingToCartItem: PropTypes.object,
	addingToCartQuantity: PropTypes.number.isRequired,
	onAddingToCartQuantityChange: PropTypes.func.isRequired,
	onCancelAddingToCart: PropTypes.func.isRequired,
	onFinishAddingToCart: PropTypes.func.isRequired,
	showAddedToCart: PropTypes.bool.isRequired,
	onCloseAddedToCart: PropTypes.func.isRequired,
	onViewCart: PropTypes.func.isRequired,
	cart: PropTypes.object.isRequired,

	onCreateCategory: PropTypes.func.isRequired,
	isCreatingCategory: PropTypes.bool.isRequired,
	onEditCategory: PropTypes.func.isRequired,
	isEditingCategory: PropTypes.bool.isRequired,
	editingCategory: PropTypes.object,

	categoryName: PropTypes.string,
	onCategoryNameChange: PropTypes.func.isRequired,
	isSavingCategory: PropTypes.bool.isRequired,
	onCancelEditingCategory: PropTypes.func.isRequired,
	onSaveCategory: PropTypes.func.isRequired,

	currentCategory: PropTypes.object,
	isRemovingCategory: PropTypes.bool.isRequired,
	onStartRemovingCategory: PropTypes.func.isRequired,
	onRemoveCategory: PropTypes.func.isRequired,
}

export default withStyles(styles)(MarketingMaterials)
