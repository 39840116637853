import { FC, useMemo } from "react"

import { ObjectEntry } from "@ncs/ts-utils"

import { ExtendableSelectProps, Select } from "../inputs"

export interface OrderShipMethodSelectorProps extends ExtendableSelectProps<ShipMethodOption> {
	includeRetailSale?: boolean
	restrictToChem?: boolean
}

export enum ShippingMethod {
	Saturday = "75",
	NextDay = "72",
	Ground = "68",
	GroundLTL = "111",
	NCSDelivery = "2",
	WillCall = "55",
}

export const shippingMethods: Record<ShippingMethod, string> = {
	[ShippingMethod.Saturday]: "Saturday - Parcel",
	[ShippingMethod.NextDay]: "Next day - Parcel",
	[ShippingMethod.Ground]: "Ground - Parcel",
	[ShippingMethod.GroundLTL]: "Ground - LTL",
	[ShippingMethod.NCSDelivery]: "NCS Delivery (Chem Only)",
	[ShippingMethod.WillCall]: "Will call",
}

/**
 * If we splice in the Retail Sale method, this will be its ID.
 */
export const retailSaleShipMethodId = "1"

/**
 * For CRUDing part orders, purchase orders, etc.
 */
export const OrderShipMethodSelector: FC<OrderShipMethodSelectorProps> = ({
	includeRetailSale,
	restrictToChem,
	...rest
}) => {
	const options = useMemo((): ShipMethodOption[] => {
		const o = (Object.entries(shippingMethods) as ObjectEntry<typeof shippingMethods>[]).map(
			([id, description]) => ({
				value: id,
				text: description,
			})
		)

		if (includeRetailSale) {
			o.push({
				value: retailSaleShipMethodId as ShippingMethod,
				text: "Retail sale",
			})
		}

		if (restrictToChem) {
			return o.filter(({ value: v }) => chemicalShipmentOptions.includes(v))
		}

		return o
	}, [includeRetailSale, restrictToChem])

	return <Select label="Ship method" {...rest} options={options} />
}

export const chemicalShipmentOptions = [
	ShippingMethod.Ground,
	ShippingMethod.WillCall,
	ShippingMethod.NCSDelivery,
]

type ShipMethodOption = {
	value: ShippingMethod
	text: string
}
