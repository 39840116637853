import { FC, useMemo, useState } from "react"
import { unpythonify } from "@ncs/ts-utils/"
import {
	GenericLineItem,
	LineItemType,
	makeApiErrorMessage,
	GenericLineItemWithBasePrice,
	useLinePricingPost,
} from "@ncs/ncs-api"
import {
	Box,
	DateTimeInput,
	EmptyValueDash,
	ExtendableModalProps,
	Label,
	Modal,
	Paragraph,
	useIsSaving,
} from "@ncs/web-legos"
import { SaveLineItemExtras } from "~/components"
import dayjs, { Dayjs } from "dayjs"
import { round } from "lodash"

export interface LogTimeModalProps extends ExtendableModalProps {
	lineItems: GenericLineItem[]
	onSave: (
		newLineItem: GenericLineItemWithBasePrice,
		extras: SaveLineItemExtras
	) => void | Promise<void>
	shipToId: string
	billToId: string | null
}

export const LogTimeModal: FC<LogTimeModalProps> = ({
	lineItems,
	onSave,
	shipToId,
	billToId,
	...rest
}) => {
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)
	const [startTime, setStartTime] = useState<Dayjs | null>(dayjs().subtract(1, "day"))
	const [endTime, setEndTime] = useState<Dayjs | null>(dayjs())
	const [laborHours, setLaborHours] = useState<string | null>(null)
	const [overtimeHours, setOvertimeHours] = useState<string | null>(null)
	const linePricingPost = useLinePricingPost()

	const splitLaborHours = (start: Dayjs | null, end: Dayjs | null) => {
		if (!start || !end) {
			return
		}

		if ([0, 6].includes(start.day())) {
			setLaborHours("0")
			setOvertimeHours(displayDiff(start, end))
			return
		}

		const cutOffTime = start.clone().set("hours", 16).set("minutes", 30)
		if (end > cutOffTime) {
			if (start > cutOffTime) {
				setLaborHours("0")
				setOvertimeHours(displayDiff(start, end))
				return
			} else {
				setLaborHours(displayDiff(start, cutOffTime))
				setOvertimeHours(displayDiff(cutOffTime, end))
				return
			}
		}
		setLaborHours(displayDiff(start, end))
		setOvertimeHours("0")
	}

	const displayDiff = (start: Dayjs, end: Dayjs) => {
		const ms_per_hour = 1000 * 60 * 60
		const utcStartDate = Date.UTC(
			start.year(),
			start.month(),
			start.day(),
			start.hour(),
			start.minute()
		)
		const utcEndDate = Date.UTC(end.year(), end.month(), end.day(), end.hour(), end.minute())
		return round(
			Math.round(Math.abs(((utcEndDate - utcStartDate) / ms_per_hour) * 100)) / 100,
			2
		).toLocaleString()
	}

	// eslint-disable-next-line
	useMemo(() => splitLaborHours(startTime, endTime), [startTime, endTime])

	const handleSave = async () => {
		try {
			setSaving()
			if (laborHours !== "0") {
				// If there is an existing labor hours line item, merge the quantities
				if (
					lineItems.some((item) => {
						return item.lineTypeId === LineItemType.LaborHours
					})
				) {
					const lineItem = lineItems.find((item) => {
						return item.lineTypeId === LineItemType.LaborHours
					})
					const response = await linePricingPost({
						customerId: shipToId,
						billToId,
						lines: [
							{
								lineTypeId: LineItemType.LaborHours,
								partId: null,
							},
						],
					})
					const linePricing = unpythonify(response.data.lines[0])
					const newQuantity = lineItem ? lineItem.quantity + Number(laborHours) : 0
					const finalPrice = linePricing.contractCovered ? 0 : linePricing.netPrice
					const newLineItem: GenericLineItemWithBasePrice = {
						lineTypeId: LineItemType.LaborHours,
						part: null,
						description: "Labor Hours",
						quantity: newQuantity,
						finalPrice: finalPrice,
						requestedPrice: null,
						basePrice: linePricing.unitPrice,
						subtotal: finalPrice * newQuantity,
						taxRate: linePricing.taxRate / 100,
						priceOverrideReason: null,
						reasonComment: null,
						overridePermission: null,
						systemGeneratedLine: false,
						originalSystemGeneratedPrice: null,
						isUnderWarranty: undefined,
						isBillable: undefined,
					}
					const extras: SaveLineItemExtras = {
						isEdit: true,
						isOverrideRequest: false,
						binId: null,
						basePriceWasNull: false,
					}
					await onSave(newLineItem, extras)
				} else {
					// otherwise, make a new labor hours line item
					const response = await linePricingPost({
						customerId: shipToId,
						billToId,
						lines: [
							{
								lineTypeId: LineItemType.LaborHours,
								partId: null,
							},
						],
					})
					const linePricing = unpythonify(response.data.lines[0])
					const newQuantity = Number(laborHours)
					const finalPrice = linePricing.contractCovered ? 0 : linePricing.netPrice
					const newLineItem: GenericLineItemWithBasePrice = {
						lineTypeId: LineItemType.LaborHours,
						part: null,
						description: "Labor Hours",
						quantity: newQuantity,
						finalPrice: finalPrice,
						requestedPrice: null,
						basePrice: linePricing.unitPrice,
						subtotal: finalPrice * newQuantity,
						taxRate: linePricing.taxRate / 100,
						priceOverrideReason: null,
						reasonComment: null,
						overridePermission: null,
						systemGeneratedLine: false,
						originalSystemGeneratedPrice: null,
						isUnderWarranty: undefined,
						isBillable: undefined,
					}
					const extras: SaveLineItemExtras = {
						isEdit: false,
						isOverrideRequest: false,
						binId: null,
						basePriceWasNull: false,
					}
					await onSave(newLineItem, extras)
				}
			}
			if (overtimeHours !== "0") {
				// If there is an existing overtime line item, merge the quantities
				if (
					lineItems.some((item) => {
						return item.lineTypeId === LineItemType.LaborOvertime
					})
				) {
					const lineItem = lineItems.find((item) => {
						return item.lineTypeId === LineItemType.LaborOvertime
					})
					const response = await linePricingPost({
						customerId: shipToId,
						billToId,
						lines: [
							{
								lineTypeId: LineItemType.LaborOvertime,
								partId: null,
							},
						],
					})
					const linePricing = unpythonify(response.data.lines[0])
					const newQuantity = lineItem ? lineItem.quantity + Number(overtimeHours) : 0
					const finalPrice = linePricing.contractCovered ? 0 : linePricing.netPrice
					const newLineItem: GenericLineItemWithBasePrice = {
						lineTypeId: LineItemType.LaborOvertime,
						part: null,
						description: "Labor/Overtime",
						quantity: newQuantity,
						finalPrice: finalPrice,
						requestedPrice: null,
						basePrice: linePricing.unitPrice,
						subtotal: finalPrice * newQuantity,
						taxRate: linePricing.taxRate / 100,
						priceOverrideReason: null,
						reasonComment: null,
						overridePermission: null,
						systemGeneratedLine: false,
						originalSystemGeneratedPrice: null,
						isUnderWarranty: undefined,
						isBillable: undefined,
					}
					const extras: SaveLineItemExtras = {
						isEdit: true,
						isOverrideRequest: false,
						binId: null,
						basePriceWasNull: false,
					}
					await onSave(newLineItem, extras)
				} else {
					// Otherwise make a new one
					const response = await linePricingPost({
						customerId: shipToId,
						billToId,
						lines: [
							{
								lineTypeId: LineItemType.LaborOvertime,
								partId: null,
							},
						],
					})
					const linePricing = unpythonify(response.data.lines[0])
					const newQuantity = Number(overtimeHours)
					const finalPrice = linePricing.contractCovered ? 0 : linePricing.netPrice
					const newLineItem: GenericLineItemWithBasePrice = {
						lineTypeId: LineItemType.LaborOvertime,
						part: null,
						description: "Labor/Overtime",
						quantity: newQuantity,
						finalPrice: finalPrice,
						requestedPrice: null,
						basePrice: linePricing.unitPrice,
						subtotal: finalPrice * newQuantity,
						taxRate: linePricing.taxRate / 100,
						priceOverrideReason: null,
						reasonComment: null,
						overridePermission: null,
						systemGeneratedLine: false,
						originalSystemGeneratedPrice: null,
						isUnderWarranty: undefined,
						isBillable: undefined,
					}
					const extras: SaveLineItemExtras = {
						isEdit: false,
						isOverrideRequest: false,
						binId: null,
						basePriceWasNull: false,
					}
					await onSave(newLineItem, extras)
				}
			}
			rest.onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
			endSaving()
		}
	}

	return (
		<Modal
			{...rest}
			title="Log Time"
			errorText={errorText}
			rightButtons={{
				buttonText: "Add Time",
				onClick: handleSave,
				isLoading: isSaving(),
			}}
		>
			<DateTimeInput
				label={"Start Time"}
				value={startTime}
				muiProps={{ disableFuture: true }}
				onChange={(newDate) => {
					setErrorText(null)
					if (endTime && newDate) {
						if (newDate > endTime) {
							setErrorText("End Time Must Be After Start Time")
							setStartTime(endTime)
						} else {
							setStartTime(newDate)
						}
					} else {
						setStartTime(newDate)
					}
				}}
			></DateTimeInput>
			<DateTimeInput
				label={"End Time"}
				value={endTime}
				muiProps={{ disableFuture: true }}
				onChange={(newDate) => {
					setErrorText(null)
					if (startTime && newDate) {
						if (newDate < startTime) {
							setErrorText("End Time Must Be After Start Time")
							setEndTime(startTime)
						} else {
							setEndTime(newDate)
						}
					} else {
						setEndTime(newDate)
					}
				}}
			></DateTimeInput>
			<Box>
				<Label>Time LH - Labor Hours</Label>
				<Paragraph>{laborHours ? laborHours : <EmptyValueDash />}</Paragraph>
			</Box>
			<Box>
				<Label>Time LO - Labor Overtime</Label>
				<Paragraph>{overtimeHours ? overtimeHours : <EmptyValueDash />}</Paragraph>
			</Box>
		</Modal>
	)
}
