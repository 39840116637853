import { FC, useState } from "react"

import {
	PartStatus,
	InventoryPart,
	makeApiErrorMessage,
	useUpdateInventoryPart,
} from "@ncs/ncs-api"
import { isEnumMember } from "@ncs/ts-utils"
import { ExtendableModalProps, Modal, useIsSaving, useToast } from "@ncs/web-legos"

import { PartStatusSelector } from "~/components"

export interface EditPartStatusModalProps extends ExtendableModalProps {
	part: InventoryPart
}

export const EditPartStatusModal: FC<EditPartStatusModalProps> = ({ part, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)
	const [status, setStatus] = useState<PartStatus | null>(() => {
		const id = part.status?.toString()
		if (isEnumMember(id, PartStatus)) return id
		return null
	})

	const updatePart = useUpdateInventoryPart()

	const handleSave = async () => {
		try {
			setSaving()
			await updatePart({
				id: part.id,
				updates: {
					status: status ?? null,
				},
			})
			makeSuccessToast("Part updated")
			rest.onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
			endSaving()
		}
	}

	return (
		<Modal
			{...rest}
			title="Edit Part Status"
			errorText={errorText}
			rightButtons={{
				buttonText: "Update",
				onClick: handleSave,
				isLoading: isSaving(),
				disabled: (part.status?.toString() ?? null) === status,
			}}
		>
			<PartStatusSelector
				value={status}
				onChange={setStatus}
				fillContainer
				disableNoSelectionOption={false}
			/>
		</Modal>
	)
}
