import { FC, useMemo } from "react"

import { useCustomerBillTos } from "@ncs/ncs-api"

import { useChangeCallback } from "../../util"
import { ExtendableSelectProps, Select } from "../inputs"

interface BillToOption {
	text: string
	value: string
	isDefault: boolean
}

export interface CustomerBillToSelectorProps extends ExtendableSelectProps<BillToOption> {
	customerId: string | null
	emptyValueFallback?: string | null
	addBillToInternal?: boolean
	isDisabled?: boolean
}

export const CustomerBillToSelector: FC<CustomerBillToSelectorProps> = ({
	customerId,
	onChange,
	emptyValueFallback = null,
	addBillToInternal,
	isDisabled = false,
	...rest
}) => {
	const [billTos, isLoading] = useCustomerBillTos(customerId)

	const options = useMemo(() => {
		const combinedOptions: BillToOption[] = []

		if (addBillToInternal) {
			combinedOptions.push(internalBillToOption)
		}

		combinedOptions.push(
			...(billTos ?? []).map((b) => ({
				value: b.id,
				text: b.name,
				isDefault: b.isDefault,
			}))
		)

		return combinedOptions
	}, [billTos, addBillToInternal])

	// When the billTos change, that means the customer ID changed, so go ahead and select their default.
	useChangeCallback(options, (newOptions) => {
		const billToToSet = newOptions.find((b) => b.isDefault === true)

		// If we found a default, set it.
		if (billToToSet) {
			onChange(billToToSet.value)
		} else if (customerId && addBillToInternal) {
			// If not, set to internal if we can. It'll be the first.
			onChange(options[0]?.value ?? emptyValueFallback)
		} else {
			// Otherwise, we should just empty this field out.
			onChange(emptyValueFallback)
		}
	})

	return (
		<Select
			disabled={isDisabled ?? (isLoading || !customerId)}
			label="Bill to"
			{...rest}
			onChange={onChange}
			options={customerId ? options : []}
		/>
	)
}

const internalBillToOption: BillToOption = {
	text: "Bill To Internal",
	value: "2",
	isDefault: false,
}
