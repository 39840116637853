import { FC, useMemo } from "react"

import { InventoryLocation, useInventoryLocations } from "@ncs/ncs-api"

import { useInitialId } from "../../util/use-initial-id"
import { ExtendableSearchableSelectProps, SearchableSelect } from "../inputs"
import { ErrorText } from "../typography"

export interface LocationSelectorProps extends ExtendableSearchableSelectProps<InventoryLocation> {
	/** Pass in an ID that you want the selector to seed as the initial state. Let's you
	 * keep just an ID somewhere else, like in the URL, rather than needing to store a full
	 * `InventoryLocation` object. */
	initialId?: string | null
	/* Splice in an extra option for Grimes? */
	addFakeGrimes?: boolean
	/**
	 * Only show warehouses?
	 */
	warehousesOnly?: boolean
	/**
	 * Any filter function you want to apply to the options.
	 */
	filter?: (option: InventoryLocation) => boolean
	/**
	 * Only show locations linked to the customers ship_to_customer_id?
	 */
	customerId?: string | null
}

/**
 * There is no actual `InventoryLocation` for Grimes because KBM controls locations.
 * So, we'll assemble a fake one here and use it throughout as a substitute when needed.
 */
export const fakeGrimes: InventoryLocation = {
	id: "-1",
	companyId: 1234567,
	description: "Grimes",
	isTech: false,
	isWarehouse: true,
	locationCode: "FAKE!",
	regionId: null,
	territoryId: null,
	userId: null,
	customerId: null,
}

export const LocationSelector: FC<LocationSelectorProps> = ({
	value,
	onChange,
	initialId,
	addFakeGrimes,
	warehousesOnly,
	customerId,
	filter: customFilter,
	...rest
}) => {
	const [locations, loading] = useInventoryLocations(customerId)

	useInitialId({
		initialId,
		onChange,
		currentValue: value,
		findInitialOption: () => {
			return addFakeGrimes && initialId === fakeGrimes.id ?
					fakeGrimes
				:	(locations ?? []).find((l) => l.id === initialId)
		},
	})

	const getOptionLabel = (option: InventoryLocation) => {
		if (option.id === fakeGrimes.id) {
			return "GRIMES"
		}

		return `(${option.locationCode})${option.description ? ` ${option.description}` : ""}`
	}

	const options = useMemo(() => {
		return (addFakeGrimes ? [...(locations ?? []), fakeGrimes] : locations ?? []).filter(
			(l) => {
				const passesWarehouse = !warehousesOnly || l.isWarehouse
				const passesCustomFilter = typeof customFilter !== "function" || customFilter(l)

				return passesWarehouse && passesCustomFilter
			}
		)
	}, [addFakeGrimes, locations, warehousesOnly, customFilter])

	return (
		<>
			<SearchableSelect
				sortingParam="description"
				getOptionLabel={getOptionLabel}
				isLoading={loading}
				label="Location"
				placeholder="Search for a location..."
				{...rest}
				options={options}
				value={value ?? null}
				onItemSelect={onChange}
				disableOptionsAggregation
			/>
			{locations?.length === 0 && (
				<ErrorText mb={1}>
					No locations linked to customer ship to, please reach out to IT for resolution
				</ErrorText>
			)}
		</>
	)
}
