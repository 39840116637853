import { FC, useMemo, useState } from "react"

import {
	AppliedPromotions,
	CreateWorkOrderPostLineItems,
	Equipment,
	GenericLineItem,
	makeApiErrorMessage,
	useCreateWorkOrder,
} from "@ncs/ncs-api"
import {
	CustomerEquipmentSelector,
	ExtendableModalProps,
	HeadingDivider,
	Modal,
	SiteStatusSelector,
	TextInput,
	useIsSaving,
} from "@ncs/web-legos"
import { CreateWorkOrderLineItems } from "./CreateWorkOrderLineItems"
import { BooleanFormField } from "~/components"

export interface CreateWorkOrderModalProps extends ExtendableModalProps {
	customerId: string
	billToCustomerId: string | undefined
	dispatchId: string
}

export const CreateWorkOrderModal: FC<CreateWorkOrderModalProps> = ({
	customerId,
	billToCustomerId,
	dispatchId,
	...rest
}) => {
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)
	const [selectedSiteStatus, setSelectedSiteStatus] = useState<string | null>(null)
	const [equipmentNotes, setEquipmentNotes] = useState<string | null>(null)
	const [selectedEquipment, setSelectedEquipment] = useState<Equipment | null>(null)
	const [lineItemsToSubmit, setLineItemsToSubmit] = useState<GenericLineItem[]>([])
	const [lineItemErrorText, setLineItemErrorText] = useState<string | null>(null)
	const [appliedPromotions, setAppliedPromotions] = useState<AppliedPromotions[]>([])
	const [customerSignature, setCustomerSignature] = useState<string | null>(null)
	const [isResolved, setIsResolved] = useState<boolean>(false)
	const createWorkOrder = useCreateWorkOrder(dispatchId)

	const { orderSubtotal, orderTaxes, orderDiscounts, orderTotal } = useMemo(() => {
		const lineItemsSubtotal = lineItemsToSubmit.reduce(
			(prev, lineItem) => prev + lineItem.finalPrice * lineItem.quantity,
			0
		)
		const lineItemsTaxes = lineItemsToSubmit.reduce(
			(prev, lineItem) => prev + lineItem.finalPrice * lineItem.quantity * lineItem.taxRate,
			0
		)
		const subtotal = lineItemsSubtotal
		const discounts = appliedPromotions.reduce(
			(discountsTotal, promo) => discountsTotal + promo.discount,
			0
		)

		const total = subtotal + lineItemsTaxes - discounts

		return {
			orderSubtotal: subtotal,
			orderTaxes: lineItemsTaxes,
			orderDiscounts: discounts,
			orderTotal: total,
		}
	}, [lineItemsToSubmit, appliedPromotions])

	const handleSave = async () => {
		if (!selectedSiteStatus) {
			setErrorText("Please Select a Site Status")
			return
		}
		const submissionItems: CreateWorkOrderPostLineItems[] = []
		lineItemsToSubmit.forEach((element) => {
			submissionItems.push({
				partId: element.part ? element.part.id : "",
				binId: null,
				description: element.description,
				lineTypeId: element.lineTypeId,
				unitPrice: element.basePrice,
				netPrice: element.finalPrice,
				quantity: element.quantity,
				taxRateUsed: element.taxRate,
				subTotal: element.subtotal,
				taxTotal: element.finalPrice,
				lineReasonId: element.reasonComment,
			})
		})
		const equipment =
			selectedEquipment ?
				[
					{
						comments: equipmentNotes,
						endTime: "",
						startTime: "",
						id: selectedEquipment.id,
						lineItems: submissionItems,
					},
				]
			:	null
		const miscItem =
			selectedEquipment ? null : (
				{
					comments: equipmentNotes,
					endTime: "",
					startTime: "",
					lineItems: submissionItems,
				}
			)
		try {
			setSaving()
			await createWorkOrder({
				equipment: equipment,
				miscItem: miscItem,
				chemical: null,
				waterInfo: null,
				packages: null,
				signature: customerSignature ? true : false,
				signatureName: customerSignature,
				siteStatusId: selectedSiteStatus,
				subTotal: orderSubtotal,
				taxTotal: orderTotal,
				total: orderTotal,
				workComplete: isResolved,
				paymentMethodId: null,
				squarePaymentId: null,
				techLocationId: null,
			})
			rest.onClose()
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			title="Create Work Order"
			errorText={errorText}
			rightButtons={{
				buttonText: "Save",
				onClick: handleSave,
				isLoading: isSaving(),
			}}
		>
			<BooleanFormField
				labelText="Resolve Dispatch?"
				value={isResolved}
				onChange={setIsResolved}
				isEditing={true}
			/>
			<HeadingDivider variant="h5">Work Completed</HeadingDivider>
			<SiteStatusSelector
				label="Site Status"
				value={selectedSiteStatus}
				onChange={setSelectedSiteStatus}
				showNoSelectionOption={true}
			/>
			<CustomerEquipmentSelector
				customerId={customerId}
				value={selectedEquipment}
				onChange={setSelectedEquipment}
			/>
			<TextInput
				value={equipmentNotes}
				onChange={setEquipmentNotes}
				label={"Notes"}
				maxLength={1000}
			/>
			<TextInput
				value={customerSignature}
				onChange={setCustomerSignature}
				label={"Customer Signature"}
				maxLength={100}
			/>
			<HeadingDivider variant="h5">Line Items</HeadingDivider>
			<CreateWorkOrderLineItems
				lineItemsToSubmit={lineItemsToSubmit}
				setLineItemsToSubmit={setLineItemsToSubmit}
				lineItemErrorText={lineItemErrorText}
				setLineItemErrorText={setLineItemErrorText}
				orderSubtotal={orderSubtotal}
				orderTaxes={orderTaxes}
				orderDiscounts={orderDiscounts}
				orderTotal={orderTotal}
				appliedPromotions={appliedPromotions}
				setAppliedPromotions={setAppliedPromotions}
				customerId={customerId}
				billToCustomerId={billToCustomerId}
			/>
		</Modal>
	)
}
