import React, { useState } from "react"

import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core"
import get from "lodash/get"
import omit from "lodash/omit"
import pick from "lodash/pick"
import PropTypes from "prop-types"

import { grayColor } from "@ncs/bricks/assets/jss/material-dashboard-pro-react"
import buttonsStyle from "@ncs/bricks/assets/jss/material-dashboard-pro-react/components/buttonStyle"
import {
	ButtonWithIcon,
	ConditionalContent,
	Dialog,
	GridContainer,
	GridItem,
	Input,
	InputAsDisplay,
	NumericInput,
	Select,
	withCallApi,
} from "@ncs/bricks/components"

import { PartSelector } from "../../components"
import { getLinePricing } from "../../redux/services/pricing"
import { LINE_ITEM_TYPES } from "../../util/constants"

import { Add, Edit } from "@material-ui/icons"

const styles = {
	...buttonsStyle,
	pageTitle: {
		color: grayColor[2],
		textDecoration: "none",
		textAlign: "center",
	},
	sectionCard: {
		marginBottom: "0 !important",
	},
	addLineItemFooterCell: {
		textAlign: "center",
		width: "100%",
	},
	center: {
		textAlign: "center",
	},
	leftAlignedModal: {
		textAlign: "left",
	},
	noWrap: {
		whiteSpace: "noWrap",
	},
}
const useStyles = makeStyles(styles)

function AddLineItemDialog(props) {
	const initialState = {
		isAddingLineItem: false,
		description: "",
		quantity: 1,
		netPrice: 0,
		returnedNetPrice: 0,
		subTotal: 0,
		unitTax: 0,
		taxRate: 0,
		part: null,
		partId: null,
		isLoading: false,
		lineItemTypeId: LINE_ITEM_TYPES.Parts._id,
		useCustomerContract: false,
		showContractSelect: false,
	}
	const [state, setWholeState] = useState(initialState)
	const [showOOSWarning, setShowOOSWarning] = useState(false)
	const setState = (newState) => {
		setWholeState({
			...state,
			...newState,
		})
	}
	const resetState = () => setState(initialState)

	const lineItemTypes = [
		LINE_ITEM_TYPES.Parts,
		LINE_ITEM_TYPES.FreightChemical,
		LINE_ITEM_TYPES.Freight,
		LINE_ITEM_TYPES.LaborHours,
		LINE_ITEM_TYPES.FlatRateTravelCharge,
		LINE_ITEM_TYPES.FlatRateTravelCharge,
		LINE_ITEM_TYPES.OutsidePurchase,
		LINE_ITEM_TYPES.SubContractCharge,
		LINE_ITEM_TYPES.InspectionOnly,
		LINE_ITEM_TYPES.Salt,
		LINE_ITEM_TYPES.OilChange,
		LINE_ITEM_TYPES.AppointmentNotification,
		LINE_ITEM_TYPES.TankEndorsement,
		LINE_ITEM_TYPES.StopCharge,
		LINE_ITEM_TYPES.Redelivery,
		LINE_ITEM_TYPES.ProtectiveService,
		LINE_ITEM_TYPES.PrivateResidenceFee,
		LINE_ITEM_TYPES.PalletJack,
		LINE_ITEM_TYPES.LimitedAccess,
		LINE_ITEM_TYPES.JobSiteDelivery,
		LINE_ITEM_TYPES.InsideDeliveryPickup,
		LINE_ITEM_TYPES.HighCostRegion,
		LINE_ITEM_TYPES.HandlingFee,
		LINE_ITEM_TYPES.HazmatFee,
		LINE_ITEM_TYPES.GuaranteedDelivery,
	]

	const handleSetAdding = () => {
		if (props.isEdit === false) {
			setState({ isAddingLineItem: true })
		} else {
			setState({
				description: props.lineDescription,
				quantity: props.lineQuantity,
				netPrice: props.lineNetPrice,
				returnedNetPrice: props.lineNetPrice,
				subTotal: props.lineSubTotal,
				unitTax: props.lineUnitTax,
				part: props.linePart,
				partId: props.linePartId,
				lineItemTypeId: props.lineItemTypeId,
				taxRate: props.lineNetPrice !== 0 ? props.lineUnitTax / props.lineNetPrice : 0,
				isAddingLineItem: true,
				lineId: props.lineId,
			})
		}
	}

	const getLineItemTypePricingDetails = (getLinePricingResult) => {
		const lineInfo = (getLinePricingResult?.lines || [{}]).pop()
		const contractCovered = get(lineInfo, "contract_covered", false)
		const netPrice = get(lineInfo, "net_price", 0)
		const taxRate = get(lineInfo, "tax_rate", 0) / 100
		const unitTax = netPrice * taxRate

		return {
			isLoading: false,
			netPrice: contractCovered ? 0.0 : netPrice,
			returnedNetPrice: netPrice,
			subTotal: contractCovered ? 0.0 : netPrice,
			unitTax: contractCovered ? 0.0 : unitTax,
			taxRate: taxRate,
			showContractSelect: contractCovered,
			useCustomerContract: contractCovered,
		}
	}

	const handleUpdateLineItemType = (lineItemId) => {
		// eventually this needs to be allowed on edit - but for times sake not now
		if (props.isEdit === true) {
			return
		}

		setState({ isLoading: true })
		let description = get(
			lineItemTypes.find((item) => item._id === lineItemId),
			"description",
			""
		)

		if (lineItemId === LINE_ITEM_TYPES.Parts._id) {
			setState({
				lineItemTypeId: lineItemId,
				description,
				isLoading: false,
			})
			return
		}

		let params = {
			customer_id: props.customerId,
			bill_to_id: props.billToId,
			lines: [
				{
					part_id: null,
					line_type_id: lineItemId,
				},
			],
		}

		props.callApi(getLinePricing(params)).then(({ payload: pricing }) => {
			const pricingDetails = getLineItemTypePricingDetails(pricing)
			const typesThatIgnorePricing = [
				LINE_ITEM_TYPES.Freight._id,
				LINE_ITEM_TYPES.FreightChemical._id,
				LINE_ITEM_TYPES.OutsidePurchase._id,
				LINE_ITEM_TYPES.SubContractCharge._id,
			]
			setState({
				lineItemTypeId: lineItemId,
				description,
				quantity: 1,
				...pricingDetails,
				...(typesThatIgnorePricing.includes(lineItemId) ?
					{
						netPrice: 0.0,
						subTotal: 0.0,
						unitTax: 0.0,
					}
				:	{}),
			})
		})
	}

	const handleConfirm = () => {
		if (!state.description) {
			return
		}

		setState({ isLoading: true })
		let item = pick(state, [
			"quantity",
			"lineItemTypeId",
			"netPrice",
			"part",
			"partId",
			"description",
			"subTotal",
			"unitTax",
			"taxRate",
			"lineId",
		])
		if (props.isEdit === true) {
			props.onUpdateItem(item)
		} else {
			props.onSubmit(item)
		}
		resetState()
	}

	const handleRemoveItem =
		props.isEdit ?
			() => {
				setState({ isLoading: true })
				let item = pick(state, [
					"quantity",
					"lineItemTypeId",
					"netPrice",
					"part",
					"partId",
					"description",
					"subTotal",
					"unitTax",
					"taxRate",
					"lineId",
				])
				props.onDelete(item)
				resetState()
			}
		:	null

	const handlePartSelected = (selectedPart) => {
		if (props.showOutOfStock && selectedPart?.is_out_of_stock) {
			setShowOOSWarning(true)
		}
		handleUpdatePart(selectedPart)
	}

	const handleConfirmOOSWarning = () => {
		setShowOOSWarning(false)
	}

	const handleUpdatePart = (part) => {
		if (!part) {
			setState({
				...omit(initialState, ["isAddingLineItem", "lineItemTypeId"]),
				quantity: 0,
			})
			return
		}

		setState({ isLoading: true })
		let params = {
			customer_id: props.customerId,
			bill_to_id: props.billToId,
			lines: [
				{
					part_id: part._id,
					line_type_id: LINE_ITEM_TYPES.Parts._id,
				},
			],
		}
		props.callApi(getLinePricing(params)).then(({ payload: pricing }) => {
			let pricingDetails = getLineItemTypePricingDetails(pricing)

			setState({
				part,
				partId: part._id,
				description: part.part_number.concat(" - ", part.description),
				quantity: 1,
				...pricingDetails,
			})
		})
	}

	const toggleUseCustomerContract = () => {
		if (state.useCustomerContract === true) {
			setState({
				useCustomerContract: false,
				netPrice: state.returnedNetPrice,
				subTotal: state.returnedNetPrice * state.quantity,
				unitTax: state.returnedNetPrice * state.taxRate,
			})
		} else {
			setState({
				useCustomerContract: true,
				netPrice: 0.0,
				subTotal: 0.0,
				unitTax: 0.0,
			})
		}
	}

	const handleUpdateQuantity = (quantity) => {
		setState({
			quantity: quantity,
			subTotal: (state.netPrice || 0) * quantity,
		})
	}

	const handleUpdateNetPrice = (netPrice) => {
		setState({
			netPrice: netPrice,
			subTotal: (state.quantity || 0) * netPrice,
			unitTax: netPrice * state.taxRate,
		})
	}

	const handleUpdateDescription = (description) => {
		setState({ description: description })
	}

	const classes = useStyles()
	const {
		isAddingLineItem,
		lineItemTypeId,
		quantity,
		description,
		netPrice,
		subTotal,
		isLoading,
		showContractSelect,
		useCustomerContract,
	} = state

	const { isEdit, skipRestrictedCheck, excludeRestrictedParts } = props

	const displaySubTotal = subTotal
		.toFixed(2)
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

	const isOutsidePurchase = lineItemTypeId === LINE_ITEM_TYPES.OutsidePurchase._id
	const isPart = lineItemTypeId === LINE_ITEM_TYPES.Parts._id

	return (
		<React.Fragment>
			<Dialog
				show={isAddingLineItem}
				title={isEdit === true ? "Edit Line Item" : "Add a New Line Item"}
				confirmBtnText={"Save"}
				isConfirmationAsync={true}
				onConfirm={handleConfirm}
				onCancel={resetState}
				onRemove={handleRemoveItem}
				isSaving={isLoading}
				fullWidth={true}
			>
				{isAddingLineItem && (
					<GridContainer className={classes.leftAlignedModal}>
						{showContractSelect === true ?
							<GridItem xs={6} hide={isPart && isEdit === true}>
								<Select
									id="lineItemType"
									labelText="Line Item Type"
									value={lineItemTypeId}
									onChange={handleUpdateLineItemType}
									options={lineItemTypes}
									valueAccessor={(item) => item._id}
									textAccessor={(item) => item.description}
								/>
							</GridItem>
						:	<GridItem xs={12} hide={isPart && isEdit === true}>
								<Select
									id="lineItemType"
									labelText="Line Item Type"
									value={lineItemTypeId}
									onChange={handleUpdateLineItemType}
									options={lineItemTypes}
									valueAccessor={(item) => item._id}
									textAccessor={(item) => item.description}
								/>
							</GridItem>
						}
						<GridItem xs={6} show={showContractSelect} align="center">
							<div className={classes.checkboxAndRadio}>
								<FormControlLabel
									control={
										<Checkbox
											onClick={toggleUseCustomerContract}
											checked={useCustomerContract}
											classes={{
												checked: classes.checked,
											}}
										/>
									}
									classes={{
										label: classes.label,
									}}
									label="Use Contract"
								/>
							</div>
						</GridItem>
						<ConditionalContent show={isPart}>
							{isEdit === true ?
								<GridItem xs={12}>
									<InputAsDisplay
										labelText={"Line Description"}
										value={description}
									/>
								</GridItem>
							:	<GridItem xs={12}>
									<PartSelector
										autoFocus
										onPartSelected={handlePartSelected}
										restrictionsCustomer={
											skipRestrictedCheck ? undefined : (
												props.customerId || props.billToId || undefined
											)
										}
										skipRestrictedCheck={skipRestrictedCheck}
										restricted={excludeRestrictedParts ? false : undefined}
									/>
								</GridItem>
							}
						</ConditionalContent>
						<ConditionalContent show={isOutsidePurchase}>
							{isEdit === true ?
								<GridItem xs={12}>
									<InputAsDisplay
										labelText={"Line Description"}
										value={description}
									/>
								</GridItem>
							:	<GridItem xs={12}>
									<Input
										value={description}
										id="description"
										labelText="Part Description"
										onChange={(e) => handleUpdateDescription(e.target.value)}
									/>
								</GridItem>
							}
						</ConditionalContent>
						<GridItem xs={12} md={4}>
							<NumericInput
								value={quantity}
								labelText="Quantity"
								id="quantity"
								onChange={handleUpdateQuantity}
								decimalScale={2}
							/>
						</GridItem>
						<GridItem xs={12} md={4}>
							<NumericInput
								value={netPrice}
								labelText="Net Price"
								id="net-price"
								onChange={handleUpdateNetPrice}
							/>
						</GridItem>
						<GridItem xs={12} md={4}>
							<InputAsDisplay labelText={"Line Sub Total"} value={displaySubTotal} />
						</GridItem>
					</GridContainer>
				)}
			</Dialog>
			{isEdit === true ?
				<ButtonWithIcon
					icon={<Edit />}
					onClick={handleSetAdding}
					round
					justIcon
					color="warning"
					size="sm"
				/>
			:	<ButtonWithIcon
					icon={<Add />}
					content="Add Line Item"
					onClick={handleSetAdding}
					round
					color="success"
					size="sm"
				/>
			}
			<Dialog
				show={showOOSWarning}
				title={"Part Is Out of Stock"}
				confirmBtnText={"OK"}
				isConfirmationAsync={true}
				onConfirm={handleConfirmOOSWarning}
				fullWidth={true}
			>
				<div>
					Selected Part / Chemical is currently low or out of stock. This may cause
					delays in order requests.
				</div>
			</Dialog>
		</React.Fragment>
	)
}

AddLineItemDialog.defaultProps = {
	isEdit: false,
	lineDescription: "",
	lineQuantity: 1,
	lineNetPrice: 0,
	lineSubTotal: 0,
	lineUnitTax: 0,
	linePart: null,
	linePartId: null,
	lineItemTypeId: LINE_ITEM_TYPES.Parts._id,
	lineId: null,
	showOutOfStock: true,
}

AddLineItemDialog.propTypes = {
	onSubmit: PropTypes.func,
	onDelete: PropTypes.func,
	customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	billToId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isEdit: PropTypes.bool,
	onUpdateItem: PropTypes.func,
	lineDescription: PropTypes.string,
	lineQuantity: PropTypes.number,
	lineNetPrice: PropTypes.number,
	lineSubTotal: PropTypes.number,
	lineUnitTax: PropTypes.number,
	linePart: PropTypes.object,
	linePartId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	lineItemTypeId: PropTypes.number,
	itemLocation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	lineId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	showOutOfStock: PropTypes.boolean,
	/** Unless this prop is true, if you supply a `customerId` or `billToId` then we'll pass that in
	 * as the `restrictionsCustomer` prop into PartSelector. */
	skipRestrictedCheck: PropTypes.bool,
	/** Don't send back any restricted parts at all from the PartSelector. */
	excludeRestrictedParts: PropTypes.bool,
}

export default withCallApi(AddLineItemDialog)
