import { FC } from "react"

import { PartStatus } from "@ncs/ncs-api"
import { ExtendableSelectProps, Select } from "@ncs/web-legos"

export type PartStatusSelectorProps = ExtendableSelectProps<(typeof options)[number], PartStatus>

export const PartStatusSelector: FC<PartStatusSelectorProps> = ({ ...rest }) => {
	return (
		<Select
			label="Part Status"
			{...rest}
			onChange={(v, option) => rest.onChange(option?.value ?? null)}
			options={options}
		/>
	)
}

const options = [
	{
		value: PartStatus.A,
		text: "A",
	},
	{
		value: PartStatus.D,
		text: "D",
	},
]
