import { FC, useState } from "react"

import { makeApiErrorMessage, useCreateCallLog } from "@ncs/ncs-api"
import {
	DateTimeInput,
	ExtendableModalProps,
	Modal,
	SelectBoolean,
	TextInput,
	useIsSaving,
} from "@ncs/web-legos"
import { Dayjs } from "dayjs"

export interface CreateCallLogModalProps extends ExtendableModalProps {
	dispatchId: string
}

export const CreateCallLogModal: FC<CreateCallLogModalProps> = ({ dispatchId, ...rest }) => {
	const [isResolved, setIsResolved] = useState<boolean | null>(false)
	const [contactedTime, setContactedTime] = useState<Dayjs | null>(null)
	const [timeEstimate, setTimeEstimate] = useState<Dayjs | null>(null)
	const [comments, setComments] = useState<string | null>(null)
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)
	const createCallLog = useCreateCallLog(dispatchId)

	const handleSave = async () => {
		if (!contactedTime) {
			setErrorText("Please select a contacted time.")
			return
		}
		if (!comments) {
			setErrorText("Please enter comments for this call.")
			return
		}

		try {
			setSaving()
			await createCallLog({
				comments: comments,
				contactedOn: contactedTime?.toISOString() ?? null,
				resolvesDispatch: !!isResolved,
				estimatedTimeOfArrival: timeEstimate?.toISOString() ?? null,
				dispatchId: dispatchId,
			})
			rest.onClose()
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			title="Create Call Log"
			errorText={errorText}
			rightButtons={{
				buttonText: "Save",
				onClick: handleSave,
				isLoading: isSaving(),
			}}
		>
			<DateTimeInput
				value={contactedTime}
				onChange={setContactedTime}
				clearable={false}
				label={"Contacted Time"}
			/>
			<DateTimeInput
				value={timeEstimate}
				onChange={setTimeEstimate}
				clearable={true}
				label={"ETA"}
			/>
			<SelectBoolean
				value={isResolved}
				showNoSelectionOption={false}
				onChange={setIsResolved}
				label="Resolved?"
			/>
			<TextInput
				value={comments}
				onChange={setComments}
				label={"Comments"}
				maxLength={2000}
			/>
		</Modal>
	)
}
