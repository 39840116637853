import { FC, useEffect, useMemo, useState } from "react"

import { css } from "@emotion/react"
import { Cell, Column } from "react-table"
import { LogTimeModal } from "./LogTimeModal"

import {
	AppliedPromotions,
	CheckOrderItemsResult,
	GenericLineItem,
	lineItemsAreEqual,
	LineItemType,
	lineTypesFreightish,
	lineTypesRepeatable,
	lineTypesRequireUserPrice,
	useAuth,
	useCurrentPromotions,
	usePrefetchWorkOrderLineItemTypes,
	useValidatePromotions,
} from "@ncs/ncs-api"
import { extractNumber, formatCurrency, formatNumber, unpythonify } from "@ncs/ts-utils"
import {
	Box,
	Button,
	ErrorText,
	Icon,
	IconButton,
	Label,
	Paragraph,
	Price,
	ReactStateDispatch,
	Table,
	Tooltip,
	useChangeCallback,
	useScreenSizeMatch,
	useToast,
} from "@ncs/web-legos"

import { LineItemEditorModal, SaveLineItemExtras } from "~/components"

import { getLineItemDiscounts } from "../../../../../../PartOrders/PartOrders/part-orders-util"
import { OrderSubtotalDetailsModal } from "../../../../../../PartOrders/PartOrders/components/create-order-tab/components/OrderSubtotalDetailsModal"
import { PromotionsModal } from "../../../../../../PartOrders/PartOrders/components/create-order-tab/components/PromotionsModal"

export interface CreateWorkOrderLineItemsProps {
	lineItemsToSubmit: GenericLineItem[]
	setLineItemsToSubmit: ReactStateDispatch<GenericLineItem[]>
	lineItemErrorText: string | null
	setLineItemErrorText: ReactStateDispatch<string | null>
	orderSubtotal: number
	orderTaxes: number
	orderDiscounts: number
	orderTotal: number
	appliedPromotions: AppliedPromotions[]
	setAppliedPromotions: ReactStateDispatch<AppliedPromotions[]>
	customerId: string
	billToCustomerId: string | undefined
}

export const CreateWorkOrderLineItems: FC<CreateWorkOrderLineItemsProps> = ({
	lineItemsToSubmit,
	setLineItemsToSubmit,
	lineItemErrorText,
	setLineItemErrorText,
	orderSubtotal,
	orderTaxes,
	orderDiscounts,
	orderTotal,
	appliedPromotions,
	setAppliedPromotions,
	customerId,
	billToCustomerId,
}) => {
	const [checkOrderItemsResult] = useState<CheckOrderItemsResult | null>(null)
	const [showLogTimeModal, setShowLogTimeModal] = useState<boolean>(false)
	const stockInfo = checkOrderItemsResult?.stockItems ?? null
	usePrefetchWorkOrderLineItemTypes()
	const { user } = useAuth()
	const userApps = useMemo(() => user?.apps ?? [], [user?.apps])
	const { makeSuccessToast, makeErrorToast } = useToast()
	const screenIsXs = useScreenSizeMatch("xs")
	const [showSubtotalDetailsModal, setShowSubtotalDetailsModal] = useState(false)

	const [showNewLineItemModal, setShowNewLineItemModal] = useState(false)
	const [lineItemToEdit, setLineItemToEdit] = useState<GenericLineItem | null>(null)

	const [showPromoModal, setShowPromoModal] = useState(false)

	const [promotions] = useCurrentPromotions()
	const validatePromotions = useValidatePromotions()
	const selectedShipToId = customerId
	const selectedBillToId = billToCustomerId

	const canShowRdcLevels = false

	const onLineItemSave = (newLineRaw: GenericLineItem, { isEdit }: SaveLineItemExtras) => {
		// If any of the current line items are the same type as the new one, then unless
		// it's one of the types that can have repeats, show an error and stop.
		if (
			!isEdit &&
			lineItemsToSubmit.some(
				(currentLine) =>
					currentLine.lineTypeId === newLineRaw.lineTypeId &&
					lineTypesRepeatable.includes(newLineRaw.lineTypeId) === false
			)
		) {
			makeErrorToast(
				"Line item type is already on order. Try editing or removing the existing line first."
			)
			return
		}

		// Check accessorial prices
		const accessorialPrices = Object.fromEntries(
			(checkOrderItemsResult?.accessorials ?? []).map((accessorial) => {
				return [accessorial.lineItemTypeId, accessorial]
			})
		)

		let newLine: GenericLineItem = newLineRaw
		if (accessorialPrices[newLineRaw.lineTypeId]) {
			newLine = {
				...newLineRaw,
				finalPrice: accessorialPrices[newLineRaw.lineTypeId].rate,
				subtotal: accessorialPrices[newLineRaw.lineTypeId].rate * newLineRaw.quantity,
			}
		}

		const existingEntryIndex = lineItemsToSubmit.findIndex((line) =>
			lineItemsAreEqual(line, newLine)
		)

		if (isEdit) {
			// We're editing an existing line, so blow away the old one.
			setLineItemsToSubmit((prev) =>
				prev.map((lineItem) => (lineItemsAreEqual(lineItem, newLine) ? newLine : lineItem))
			)
			makeSuccessToast("Line item updated")
		} else {
			if (existingEntryIndex !== -1) {
				// We weren't editing, but the part already existed so merge their quantities.
				setLineItemsToSubmit((prev) =>
					prev.map(
						(prevLine, i): GenericLineItem =>
							i === existingEntryIndex ?
								{
									...newLine,
									quantity: prevLine.quantity + newLine.quantity,
									subtotal:
										(prevLine.quantity + newLine.quantity) *
										newLine.finalPrice,
								}
							:	prevLine
					)
				)
				makeSuccessToast("Line item updated")
			} else {
				setLineItemsToSubmit((prev) => [...prev, newLine]) // Brand new, just add it.
				makeSuccessToast("Line item added")
			}
		}
	}

	const onLineItemDelete = (lineToDelete: GenericLineItem) => {
		setLineItemToEdit(null)
		setLineItemsToSubmit((prev) => {
			// First remove the line item that was passed in.
			let result = prev.filter((line) => !lineItemsAreEqual(line, lineToDelete))

			// If we just removed the last line of type part, then also remove all freightish lines.
			if (result.every((line) => line.lineTypeId !== LineItemType.Parts)) {
				result = result.filter((line) => !lineTypesFreightish.includes(line.lineTypeId))
			}

			return result
		})
		makeSuccessToast("Line item(s) removed")
	}

	const onLineItemEdit = (lineItem: GenericLineItem) => {
		setLineItemToEdit(lineItem)
	}

	// Clear out line items error text.
	useEffect(() => {
		if (!!lineItemErrorText && lineItemsToSubmit.length > 0) {
			setLineItemErrorText(null)
		}
	}, [lineItemErrorText, lineItemsToSubmit, setLineItemErrorText])

	// Re-check our promotions if line items change.
	useChangeCallback(lineItemsToSubmit, async (newLineItems) => {
		if (selectedShipToId && appliedPromotions.length && newLineItems.length) {
			const { data } = await validatePromotions({
				customerId: selectedShipToId,
				promotionIds: appliedPromotions.map((promo) => promo.id),
				parts: newLineItems.flatMap((line) =>
					line.part ?
						[
							{
								partId: line.part.id,
								quantity: line.quantity,
								netPrice: line.finalPrice,
							},
						]
					:	[]
				),
			})
			const validPromos = unpythonify(data).filter((promo) => promo.discount > 0)
			setAppliedPromotions(validPromos)
		} else {
			setAppliedPromotions([])
		}
	})

	const partOrderLineItemColumns: Column<GenericLineItem>[] = useMemo(
		() => [
			{
				Header: "Description",
				accessor: "description",
				Cell: ({ row: { original } }: Cell<GenericLineItem>) => {
					const discount = getLineItemDiscounts(original, appliedPromotions)

					return (
						<Box display="flex" alignItems="center" gap={0.5}>
							<span>{original.description}</span>
							{!!discount && (
								<Tooltip title="Part is being discounted by a promotion">
									<Icon icon="gift" color="success" />
								</Tooltip>
							)}
						</Box>
					)
				},
			},
			{
				// Note that this is not the `unit price` that comes from pricing endpoint.
				// It's actually the net price. We're just calling it "Unit Price" just
				// makes the most sense to the user.
				Header: "Unit Price",
				Cell: ({
					row: {
						original: {
							lineTypeId,
							basePrice,
							finalPrice,
							requestedPrice,
							overridePermission,
							originalSystemGeneratedPrice,
						},
					},
				}: Cell<GenericLineItem>) => {
					if (
						!lineTypesRequireUserPrice.includes(lineTypeId) &&
						((!!basePrice && basePrice !== (requestedPrice ?? finalPrice)) ||
							requestedPrice != null)
					) {
						const pendingApproval =
							!!overridePermission &&
							!userApps.includes(overridePermission.description)

						return (
							<Box d="flex" gap={0.5}>
								<span
									css={css`
										text-decoration: line-through;
									`}
								>
									{formatCurrency(
										originalSystemGeneratedPrice ?? basePrice ?? finalPrice
									)}
								</span>
								<span>{formatCurrency(requestedPrice ?? finalPrice)}</span>
								{pendingApproval && <span> (pending approval)</span>}
							</Box>
						)
					} else {
						return formatCurrency(finalPrice)
					}
				},
			},
			{
				Header: "Qty",
				accessor: "quantity",
				Cell: ({ row: { original } }: Cell<GenericLineItem>) => {
					if (!original.part) {
						return formatNumber(original.quantity)
					} else {
						const outOfStockQuantity =
							stockInfo?.outOfStockItems.find((p) => p.partId === original.part?.id)
								?.quantity ?? 0
						const title =
							outOfStockQuantity ?
								`DC is short by ${outOfStockQuantity}. This may affect order time.`
							:	"DC has adequate stock to fulfill this part."

						return canShowRdcLevels ?
								<Tooltip
									title={title}
									contentContainerProps={{ style: { display: "inline" } }}
								>
									<>
										<span css={quantityNumberStyle}>
											{formatNumber(original.quantity)}
										</span>
										<Icon
											icon={
												outOfStockQuantity ? "exclamation-circle" : "check"
											}
											color={outOfStockQuantity ? "warning" : "light-gray"}
										/>
									</>
								</Tooltip>
							:	formatNumber(original.quantity)
					}
				},
			},
			{
				Header: "Promotion discount",
				accessor: (original) => {
					const discount = getLineItemDiscounts(original, appliedPromotions)

					return discount ? formatCurrency(discount * -1) : "-"
				},
			},
			{
				Header: "Line Subtotal",
				accessor: (original) => {
					const discount = getLineItemDiscounts(original, appliedPromotions)

					return formatCurrency(original.subtotal - discount)
				},
			},
		],
		[stockInfo?.outOfStockItems, canShowRdcLevels, appliedPromotions, userApps]
	)

	const currentWeightTotal = useMemo(() => {
		return lineItemsToSubmit.reduce((total, line) => {
			return total + extractNumber(line.part?.weight ?? 0) * line.quantity
		}, 0)
	}, [lineItemsToSubmit])

	return (
		<>
			<Box pl={1.5}>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="flex-start"
					xsProps={{ flexDirection: "column" }}
					gap={1}
				>
					<div>
						<Button
							variant="secondary-cta"
							icon="plus-circle"
							onClick={() => setShowNewLineItemModal(true)}
							fillContainer={screenIsXs}
						>
							Add line item
						</Button>
					</div>
					<div>
						<Button
							variant="secondary-cta"
							icon="clock"
							onClick={() => setShowLogTimeModal(true)}
							fillContainer={screenIsXs}
						>
							Log Time
						</Button>
					</div>
					{!!promotions?.length && (
						<Box
							display="flex"
							flexDirection="column"
							gap={0.25}
							alignItems="flex-end"
						>
							<Button
								icon="gift"
								onClick={() => setShowPromoModal(true)}
								disabled={!selectedShipToId || !lineItemsToSubmit.length}
							>
								Promotions
							</Button>
							{appliedPromotions.map((promo) => (
								<Box key={promo.id} display="flex" gap={0.5} alignItems="center">
									<Paragraph small secondary>
										{promo.description}{" "}
									</Paragraph>
									<Paragraph small color="success" bold>
										{formatCurrency(promo.discount)}
									</Paragraph>
									<Icon icon="check" color="success" />
								</Box>
							))}
						</Box>
					)}
				</Box>

				<Box
					display="flex"
					alignItems="baseline"
					justifyContent="space-between"
					xsProps={{ flexDirection: "column" }}
					px={0.5}
					mt={2}
					mb={1}
					gap={1}
				>
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="flex-end"
						alignItems="flex-end"
						xsProps={{ alignItems: "flex-start" }}
					>
						<Box
							display="flex"
							flexWrap="wrap"
							columnGap={2}
							rowGap={1}
							justifyContent="flex-end"
							xsProps={{ flexDirection: "column" }}
						>
							<div>
								<Label>Subtotal</Label>
								<Box d="flex">
									<Price price={orderSubtotal} small />
									{!!checkOrderItemsResult?.freightTotals && (
										<Box mt={-0.35}>
											<IconButton
												icon="info-circle"
												color="gray"
												onClick={() => setShowSubtotalDetailsModal(true)}
											/>
										</Box>
									)}
								</Box>
							</div>
							<div>
								<Label>Taxes</Label>
								<Price price={orderTaxes} small />
							</div>
							{!!appliedPromotions.length && (
								<div>
									<Label>Promotions</Label>
									<Price price={orderDiscounts * -1} small />
								</div>
							)}
							<Box bl={1} pl={2} xsProps={{ bl: 0, pl: 0, bt: 1, pt: 1 }}>
								<Label>Total</Label>
								<Price price={orderTotal} small />
							</Box>
						</Box>
					</Box>
				</Box>

				{lineItemsToSubmit.length > 0 && (
					<Table
						data={lineItemsToSubmit.sort((a, b) => {
							if (!!a.part && !b.part) {
								return -1
							}
							if (!a.part && !!b.part) {
								return 1
							}
							return a.description > b.description ? 1 : -1
						})}
						columns={partOrderLineItemColumns}
						rowMenu={[
							{
								label: "Edit",
								iconName: "pencil",
								onClick: ({ original }) => onLineItemEdit(original),
								disabledAccessor: () => !selectedBillToId || !selectedShipToId,
							},
							{
								label: "Remove",
								iconName: "trash",
								onClick: ({ original }) => onLineItemDelete(original),
								disabledAccessor: ({ original }) => {
									return (
										original.systemGeneratedLine &&
										(original.lineTypeId === LineItemType.Freight ||
											original.lineTypeId === LineItemType.FuelSurcharge)
									)
								},
							},
						]}
						disableAllSorting
					/>
				)}

				{!!lineItemErrorText && <ErrorText my={1}>{lineItemErrorText}</ErrorText>}
			</Box>

			{(showNewLineItemModal || !!lineItemToEdit) && (
				<LineItemEditorModal
					lineItemToEdit={lineItemToEdit}
					onClose={() => {
						setShowNewLineItemModal(false)
						setLineItemToEdit(null)
					}}
					shipToId={selectedShipToId || null}
					billToId={selectedBillToId || null}
					onSave={onLineItemSave}
					onRemove={onLineItemDelete}
					currentWeightTotal={currentWeightTotal}
				/>
			)}
			{showPromoModal && !!promotions && !!selectedShipToId && (
				<PromotionsModal
					customerId={selectedShipToId}
					lineItems={lineItemsToSubmit}
					promotions={promotions}
					appliedPromotions={appliedPromotions}
					setAppliedPromotions={setAppliedPromotions}
					onClose={() => setShowPromoModal(false)}
				/>
			)}
			{showSubtotalDetailsModal && !!checkOrderItemsResult?.freightTotals && (
				<OrderSubtotalDetailsModal
					checkOrderItemsResult={checkOrderItemsResult}
					onClose={() => setShowSubtotalDetailsModal(false)}
				/>
			)}
			{!!showLogTimeModal && (
				<LogTimeModal
					onClose={() => setShowLogTimeModal(false)}
					lineItems={lineItemsToSubmit}
					onSave={onLineItemSave}
					shipToId={selectedShipToId}
					billToId={selectedBillToId || null}
				/>
			)}
		</>
	)
}

const quantityNumberStyle = css`
	margin-right: 0.5rem;
`

CreateWorkOrderLineItems.displayName = "CreateWorkOrderLineItems"
