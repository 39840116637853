import { FC, memo, useEffect, useState } from "react"

import { css } from "@emotion/react"
import { useFormContext } from "react-hook-form"

import {
	BrandCard,
	CustomerManufacturer,
	CustomerSystem,
	PartCategory,
	PartChemical,
	usePartCategories,
	usePartChemicals,
	FULL_PAGE_SIZE,
	useEquipmentManufacturers,
	usePartSystems,
} from "@ncs/ncs-api"
import { addOrPromote, titleCase } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Button,
	CheckboxFormField,
	Chip,
	ErrorText,
	Icon,
	Label,
	Modal,
	Paragraph,
	PartBrandSelector,
	PartCategorySelector,
	PartChemicalSelector,
	PartSystemSelector,
	RadioBoolean,
	TextInput,
	Tooltip,
	useChangeCallback,
} from "@ncs/web-legos"

import {
	BrandCardForm,
	brandCardFormDefaults,
	CurrentFiltersState,
	getFilterStateFromUrl,
	getUrlFromFilterState,
	isSearchPageUrl,
	SearchFilter,
} from "./brand-card-utils"

export interface BrandCardDestinationProps {
	card: BrandCard | null
}

export const BrandCardDestination: FC<BrandCardDestinationProps> = memo(({ card }) => {
	const { data: brands } = useEquipmentManufacturers({
		params: {
			pageSize: FULL_PAGE_SIZE,
			isActive: true,
		},
		manualPagination: true,
	})
	const [systems] = usePartSystems()
	const [categories] = usePartCategories()
	const [chemicals] = usePartChemicals()

	const {
		setValue,
		control,
		formState: { errors, submitCount },
	} = useFormContext<BrandCardForm>()

	// This is local state that needs to be instantiated with current card data if we're editing an existing card.
	const [localUrlWithParams, setLocalUrlWithParams] = useState<string>(
		card?.buttonDestinationUrl || brandCardFormDefaults.buttonDestinationUrl
	)
	const [isSearchPage, setIsSearchPage] = useState<boolean | null>(() => {
		if (card) {
			return isSearchPageUrl(card.buttonDestinationUrl)
		} else {
			return null
		}
	})
	const [currentFilters, setCurrentFilters] = useState<CurrentFiltersState>(
		getFilterStateFromUrl(
			card?.buttonDestinationUrl || brandCardFormDefaults.buttonDestinationUrl
		)
	)

	// When the card coming in changes, we need to re-instantiate our local state.
	useChangeCallback(card, (newCard) => {
		if (newCard) {
			// We can do this all just by setting isSearchPage, and the rest will observe
			// and react to the changes accordingly.
			setIsSearchPage(isSearchPageUrl(newCard.buttonDestinationUrl))
		}
	})

	const [addingFilterType, setAddingFilterType] = useState<SearchFilter | null>(null)
	const [newBrand, setNewBrand] = useState<CustomerManufacturer | null>(null)
	const [newSystem, setNewSystem] = useState<CustomerSystem | null>(null)
	const [newCategory, setNewCategory] = useState<PartCategory | null>(null)
	const [newChemical, setNewChemical] = useState<PartChemical | null>(null)

	const findFilterLabel = (id: string, filterType: SearchFilter): string => {
		switch (filterType) {
			case SearchFilter.Brand: {
				return (brands ?? []).find((b) => b.id.toString() === id)?.name ?? ""
			}
			case SearchFilter.System: {
				return (systems ?? []).find((b) => b.id.toString() === id)?.modelName ?? ""
			}
			case SearchFilter.Category: {
				return (categories ?? []).find((b) => b.id.toString() === id)?.name ?? ""
			}
			case SearchFilter.Chemical: {
				return (chemicals ?? []).find((b) => b.id.toString() === id)?.name ?? ""
			}
		}
	}

	const handleRemoveFilter = (idToDelete: string, filterType: SearchFilter) => {
		setCurrentFilters((prev) => ({
			...prev,
			[filterType]: prev[filterType].filter((id) => id !== idToDelete),
		}))
	}

	const closeModal = () => {
		setNewBrand(null)
		setNewSystem(null)
		setNewCategory(null)
		setNewChemical(null)
		setAddingFilterType(null)
	}

	const handleAddFilter = (filterType: SearchFilter | null): void => {
		if (filterType) {
			// Whichever new object is not null should be the selected one.
			const newId = newBrand?.id ?? newSystem?.id ?? newCategory?.id ?? newChemical?.id

			setCurrentFilters((prev) => ({
				...prev,
				[filterType]:
					newId?.toString() ?
						addOrPromote(newId.toString(), prev[filterType])
					:	prev[filterType],
			}))
		}

		closeModal()
	}

	// Whenever the filters change, update the URL in our local state.
	useChangeCallback(currentFilters, (newFilters) => {
		setLocalUrlWithParams((prev) => getUrlFromFilterState(prev, newFilters))
	})

	// Whenever you switch between using the search page and providing a link,
	// we need to do some resetting here in our local state. Basically we go
	// back to the way it looked when you first loaded the page.
	useChangeCallback(isSearchPage, (newIsSearch, prevIsSearch) => {
		// Going to the search page needs to set it to /shop/search.
		if (newIsSearch === true && !prevIsSearch) {
			setLocalUrlWithParams(getUrlFromFilterState("/shop/search", currentFilters))
		}
		// If you're going to the custom URL option, set it to what's on the card, or just empty.
		if (newIsSearch === false) {
			setLocalUrlWithParams(card?.buttonDestinationUrl || "")
		}
	})

	// As the local URL value changes, keep the form updated.
	useEffect(() => {
		setValue("buttonDestinationUrl", localUrlWithParams, {
			shouldValidate: true,
		})
	}, [setValue, localUrlWithParams])

	return (
		<>
			<Paragraph small color="secondary" breakAnywhere>
				{localUrlWithParams}
			</Paragraph>
			<RadioBoolean
				htmlName="search-page"
				value={isSearchPage}
				onChange={setIsSearchPage}
				yesText="Search results page with filters"
				noText="Custom URL"
			/>

			<AnimatedEntrance show={isSearchPage === true} ml={1.9}>
				{Object.values(SearchFilter).map((f) => (
					<Box key={f} display="flex" flexDirection="column" mb={1.35} rowGap={0.35}>
						<Label>
							{f === SearchFilter.Category ?
								`${titleCase(f.slice(0, -1))}ies`
							:	`${titleCase(f)}s`}
						</Label>
						<Box
							display="flex"
							alignItems="center"
							flexWrap="wrap"
							columnGap={0.5}
							rowGap={0.75}
						>
							{currentFilters[f].map((id) => {
								const label = findFilterLabel(id, f)
								if (!label) return null

								return (
									<Chip
										key={id}
										label={label}
										onDelete={() => handleRemoveFilter(id, f)}
									/>
								)
							})}
							<Button icon="plus-circle" onClick={() => setAddingFilterType(f)}>
								Add {f}
							</Button>
						</Box>
					</Box>
				))}
			</AnimatedEntrance>

			<AnimatedEntrance show={isSearchPage === false} ml={1.9}>
				<Box display="block" textAlign="right" mt={-1}>
					<Box display="inline-block">
						<Tooltip
							title={
								<>
									URLs that start with a "/" will be appended to the customer
									portal's normal base URL. For example, entering
									"/service-requests" here goes to{" "}
									<strong
										css={css`
											word-break: break-all;
										`}
									>
										https://portal.ncswash.com/service-requests
									</strong>
									.
									<br />
									If you'd like to direct to a URL outside the portal, enter in
									the full URL. For example, entering
									"https://my-example-url.com" here will go to{" "}
									<strong>https://my-example-url.com</strong>.
								</>
							}
						>
							<Box display="flex" alignItems="center" columnGap={0.35}>
								<Paragraph small color="secondary">
									More Info
								</Paragraph>
								<Icon icon="question-circle" color="gray" />
							</Box>
						</Tooltip>
					</Box>
				</Box>
				<TextInput
					value={localUrlWithParams}
					onChange={(newValue) => setLocalUrlWithParams(newValue ?? "")}
					placeholder="URL..."
				/>
				<CheckboxFormField
					control={control}
					name="buttonDestinationOpensNewWindow"
					label="Open in a new browser tab when clicked"
					mt={-0.5}
				/>
			</AnimatedEntrance>

			{!!errors.buttonDestinationUrl?.message && submitCount > 0 && (
				<ErrorText my={1}>{errors.buttonDestinationUrl.message}</ErrorText>
			)}

			<Modal
				isOpen={!!addingFilterType}
				onClose={closeModal}
				title={`Add ${titleCase(addingFilterType)}`}
				rightButtons={{
					buttonText: "Add",
					onClick: () => handleAddFilter(addingFilterType),
				}}
			>
				{addingFilterType === SearchFilter.Brand && (
					<PartBrandSelector value={newBrand} onChange={setNewBrand} />
				)}
				{addingFilterType === SearchFilter.System && (
					<PartSystemSelector value={newSystem} onChange={setNewSystem} />
				)}
				{addingFilterType === SearchFilter.Category && (
					<PartCategorySelector value={newCategory} onChange={setNewCategory} />
				)}
				{addingFilterType === SearchFilter.Chemical && (
					<PartChemicalSelector value={newChemical} onChange={setNewChemical} />
				)}
			</Modal>
		</>
	)
})
