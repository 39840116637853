import { FC } from "react"

import { CustomerManufacturer, FULL_PAGE_SIZE, useEquipmentManufacturers } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface PartBrandSelectorProps
	extends ExtendableSelectProps<CustomerManufacturer, CustomerManufacturer> {}

export const PartBrandSelector: FC<PartBrandSelectorProps> = ({ value, onChange, ...rest }) => {
	const { data: brands, isLoading } = useEquipmentManufacturers({
		params: {
			pageSize: FULL_PAGE_SIZE,
			isActive: true,
		},
		manualPagination: true,
	})

	return (
		<Select
			disabled={isLoading}
			label="Part brand"
			fillContainer
			{...rest}
			value={value?.id.toString() ?? null}
			onChange={(newValue, newOption) => onChange(newOption ?? null)}
			options={brands ?? []}
			valueAccessor={(option) => option.id.toString()}
			textAccessor="name"
		/>
	)
}
