import { FC, useCallback, useMemo, useState } from "react"

import { Customer, CustomerQueryParams, useCustomer, useCustomers } from "@ncs/ncs-api"

import { useInitialId } from "../../util"
import { ExtendableSearchableSelectProps, SearchableSelect } from "../inputs"
import { css } from "@emotion/react"

export interface CustomerSelectorProps extends ExtendableSearchableSelectProps<Customer> {
	isActive?: boolean
	/** Customer that you want to start selected. Note that this only needs the ID, not the full `Customer` object. */
	initialCustomerId?: string | null
	/** Exclude territory 1? */
	excludeBillTo?: boolean
	limitToServiceCustomers?: boolean | null
	/** Only active accounts? */
	accountActive?: boolean | null
	/** Ask Brad what this one means, but turning off this filter makes shiz go boom... */
	service?: boolean | null
	/** Use this to manually declare what the params on the query are. For example, you want to
	 * declare all the query params to null. */
	customerQueryParams?: Partial<CustomerQueryParams>
	isInternal?: boolean | null
}

export const CustomerSelector: FC<CustomerSelectorProps> = ({
	value,
	onChange,
	initialCustomerId,
	customerQueryParams,
	excludeBillTo,
	limitToServiceCustomers,
	isInternal,
	isActive = true,
	accountActive = true,
	service = true,
	label = "Customer",
	...rest
}) => {
	const [searchInput, setSearchInput] = useState<string | null>(null)

	// Search for customers based on the current params.
	const customersQuery = useCustomers({
		params: {
			excludeTerritory: excludeBillTo ? 1 : undefined,
			isServiceCustomer: limitToServiceCustomers,
			accountActive,
			isActive,
			isInternal,
			service,
			...customerQueryParams,
			search: searchInput,
		},
		queryConfig: {
			enabled: !!searchInput,
		},
		pageSize: 10,
	})

	// If an initial ID was passed in, we'll go fetch that so we can select it.
	const [initialCustomer, isLoadingInitialCustomer] = useCustomer(initialCustomerId ?? null, {
		queryConfig: {
			enabled: !!initialCustomerId && value == null,
		},
	})

	const findInitialOption = useCallback(() => {
		return initialCustomer
	}, [initialCustomer])

	useInitialId({
		initialId: initialCustomerId,
		onChange,
		currentValue: value,
		findInitialOption,
	})

	const getOptionLabel = (option: Customer) => {
		const pieces: string[] = []
		pieces.push(`(${option.customerNumber})`)
		pieces.push(option.name)
		if (!option.isFstActive) pieces.push("(Inactive)")
		if (option.city && option.state) {
			pieces.push(`- ${option.city}, ${option.state}`)
		}

		return pieces.join(" ")
	}

	const options = useMemo(() => {
		return value ?
				// `value` may or may not be present in the data, especially at a first.
				// Inject it here, and then filter it out of the data to prevent duplicate.
				[value, ...customersQuery.data.filter((d) => d.id !== value.id)]
			:	customersQuery.data
	}, [customersQuery.data, value])

	return (
		<SearchableSelect
			isLoading={customersQuery.isLoading || isLoadingInitialCustomer}
			label={label}
			placeholder="Search for a customer..."
			sortingParam="name"
			getOptionLabel={getOptionLabel}
			{...rest}
			value={value ?? null}
			options={options}
			onInputChange={setSearchInput}
			onItemSelect={onChange}
			useSearchIcon
		/>
	)
}
