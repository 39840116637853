import { ReactElement } from "react"

import { Control, Controller, FieldValues, Path } from "react-hook-form"

import { RadioBoolean, RadioBooleanProps } from "../inputs"

export interface RadioBooleanFormFieldProps<TFieldValues extends FieldValues>
	extends Omit<RadioBooleanProps, "value" | "onChange" | "htmlName"> {
	name: Path<TFieldValues>
	control: Control<TFieldValues>
}

export const RadioBooleanFormField = <TFieldValues extends FieldValues>({
	name,
	control,
	...rest
}: RadioBooleanFormFieldProps<TFieldValues>): ReactElement => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { value, onChange }, fieldState: { error } }) => {
				return (
					<RadioBoolean
						{...rest}
						htmlName={name}
						value={value}
						onChange={onChange}
						error={error?.message}
					/>
				)
			}}
		/>
	)
}
